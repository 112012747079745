import React, {useState} from 'react'

import { 
	Button,
	Box, 
	Grid2 as Grid,
	Typography,
	Modal,
	Checkbox,
	FormControlLabel
} from '@mui/material'

import { 
	useSelector,
	useDispatch 
} from 'react-redux'

import { logout } from '../../features/auth/authSlice'

import { EngineApi } from '../../main'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Page from '../Page'
import { theme } from '../../style/theme';
import { ThemeProvider } from '@mui/material/styles'
import '../../components/Auth/Auth.css'

const AccountId = ({accountId}) => {

	const CopyUserId = () => {
		navigator.clipboard.writeText(accountId).then(() => {
			console.log('User ID copied to clipboard');
		  },() => {
			console.error('Failed to copy');
		  });
		  
	}
	
	return (
		<Grid container direction="column" spacing={2}>
			<Grid container direction="column">
				<Grid>
					<Typography component={'span'} variant="h3" sx={{fontWeight: "bold"}}>
						<Box>
							User ID
						</Box>
					</Typography>
				</Grid>
				<Grid>
					<Typography component={'span'}>
						<Box>
							Share this User ID with others to collaborate on Tools & Filesystems seamlessly.
						</Box>
					</Typography>
				</Grid>
			</Grid>
			<Grid container direction="row" spacing={2}>
				<Grid>
					<Typography component={'span'} variant="overline" color="textPrimary">
						<Box sx={{
							pl: 1,
							pr: 1,
							bgcolor: "#e3f2fd",
							borderRadius: 1,
							textAlign: "center",
							justifyContent: "center",
							fontWeight: "bold"
						}}>
							User ID	
						</Box>
					</Typography>
				</Grid>
				<Grid>
					<Typography component={'span'} color="textSecondary">
						<Box sx={{
							textAlign: "center",
							justifyContent: "center",
						}}>
							{accountId}
						</Box>
					</Typography>
				</Grid>
				<Grid>
					<Button onClick={CopyUserId} sx={{
						border: 0,
						boxShadow: 0,
						p: 0.5,
						color: "gray"
					}}>
						<ContentCopyIcon fontSize="small"/>
					</Button>
				</Grid>
			</Grid>
		</Grid>
	)
}

const Organization = ({name}) => {
	return (
		<Grid container direction="row" spacing={2}>
		<Grid>
			<Typography component={'span'} variant="overline" color="textPrimary">
				<Box sx={{
					pl: 1,
					pr: 1,
					bgcolor: "#e3f2fd",
					borderRadius: 1,
					textAlign: "center",
					justifyContent: "center",
					fontWeight: "bold"
				}}>
					Organization
				</Box>
			</Typography>
		</Grid>
		<Grid>
			<Typography component={'span'} color="textSecondary">
				<Box sx={{
					textAlign: "center",
					justifyContent: "center",
				}}>
					{name}
				</Box>
			</Typography>
		</Grid>
	</Grid>
	)
}

const ContactInformation = ({email}) => {
	return (
		<Grid container direction="column" spacing={2}>
			<Grid container direction="column">
				<Typography component={'span'} variant="h3" sx={{fontWeight: "bold"}}>
					<Box>
						Contact Information
					</Box>
				</Typography>
			</Grid>
			<Grid container direction="row" spacing={2}>
				<Grid>
					<Typography component={'span'} variant="overline" color="textPrimary" >
						<Box sx={{
							pl: 1,
							pr: 1,
							bgcolor: "#e3f2fd",
							borderRadius: 1,
							textAlign: "center",
							justifyContent: "center",
							fontWeight: "bold"
						}}>
							email
						</Box>
					</Typography>
				</Grid>
				<Grid>
					<Typography component={'span'} color="textSecondary" >
						<Box sx={{
							textAlign: "center",
							justifyContent: "center",
						}}>
							{email}
						</Box>
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	)
}

const OrganizationList = ({organizations}) => {
	
	const orgs = []
	organizations.forEach(org => {
		orgs.push(
			<Organization name={org} key={org}/>
		)
	})

	return (
		<Grid container direction="column" spacing={2}>
			<Grid>
				<Typography component={'span'} variant="h3" sx={{fontWeight: "bold"}}>
					<Box>
						Organization Memberships
					</Box>
				</Typography>
			</Grid>
			<Grid>
				{orgs}
			</Grid>
		</Grid>
	)
}

const DeleteModal = ({open, setOpen}) => {

	const [checked, setChecked] = useState(false)
	const dispatch = useDispatch()
	const checkboxLabel = "I understand the consequences of this action"

	const deleteAccount = async () => {

		if (!checked) {
			return
		}

		console.log("Delete requested")

		await EngineApi.delete(
			"/account"
		).then((response) => {
			dispatch(logout())
		}).catch((error) => {
			if(error.response){
				console.error(error.response.data); // => the response payload 
				throw new Error(error.response.data)
			} else if (error.request) {
				console.error(error.request)
				throw new Error(error.request)
			} else {
				console.error(error.message)
				throw new Error(error.message)
			}
		})
	}

	return (
		<ThemeProvider theme={theme}>
			<Modal 
				open={open}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Grid 
					container 
					className='modal-container'
					spacing={2}
					direction="column"
					sx={{justifyContent: "center", alignItems: "center", p:10}}
				>
					<Grid xs={4} sx={{justifyContent: "center", textAlign: "center"}}>
						<Typography component={'span'} variant="h5" sx={{fontWeight: "bold"}}>
							<Box>
								Are you sure you want to delete your account?
							</Box>
						</Typography>
					</Grid>
					<Grid xs={4} sx={{justifyContent: "center", textAlign: "center"}}>
						<Typography component={'span'}>
							<Box>
								This action cannot be undone. All your Filesystems and Tools will be deleted, along with all associated data.
							</Box>
						</Typography>
					</Grid>
					<Grid xs={2} sx={{justifyContent: "center", textAlign: "center"}}>
						<FormControlLabel label={checkboxLabel} control={
							<Checkbox 
								checked={checked} 
								onChange={() => setChecked(!checked)}
							/>
						}/>
					</Grid>
					<Grid container direction="row">
						<Grid>
							<Button>Cancel</Button>
						</Grid>
						<Grid>
							<Button variant="contained" disabled={!checked} onClick={deleteAccount}>Delete Account</Button>
						</Grid>
					</Grid>
				</Grid>
			</Modal>
		</ThemeProvider>
	)

}

const DeleteAccount = () => {

	const [deleteModalOpen, setDeleteModalOpen] = useState(false)

	return (
		<Grid>
			{deleteModalOpen && <DeleteModal open={deleteModalOpen} setOpen={setDeleteModalOpen}/>}
			<Button variant="contained" onClick={() => setDeleteModalOpen(true)}>
				Delete Account
			</Button>
		</Grid>
	)
}

const AccountSettings = () => {

	const username = useSelector((state) => state.auth.username)
	const userId = useSelector((state) => state.auth.userId)

	// NOTE: This hard-coded list can be updated once we develop the Organization-level account controls
	const organizations = ["ExploreTech"]
		
	return (
		<Page name={"Account Settings"}>
			<Grid container direction="column" spacing={6}>
				<Grid>
					<AccountId accountId={userId} />
				</Grid>
				<Grid>
					<ContactInformation email={username}/>
				</Grid>
				<Grid>
					<OrganizationList organizations={organizations} />
				</Grid>
				<Grid>
					<DeleteAccount/>
				</Grid>
			</Grid>
		</Page>
	)
}

export default AccountSettings
