

export class Filesystem {
    constructor(filesystemId, filesystemName) {
        this.filesystemId = filesystemId
        this.filesystemName = filesystemName
    }


    isEqual(obj) {
        try {
            let isEqual = true
            isEqual = isEqual && (this.filesystemId === obj.filesystemId)
            isEqual = isEqual && (this.filesystemName === obj.filesystemName)
            return isEqual

        } catch {
            return false
        }
    }


    toJson() {
        const filesystemJson = {
            filesystem_id: this.filesystemId,
            filesystem_name: this.filesystemName            
        }
        return filesystemJson
    }


    static fromJson(filesystemJson) {
        return new Filesystem(
            filesystemJson.filesystem_id, 
            filesystemJson.filesystem_name
        )
    }
}
