import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Amplify } from 'aws-amplify'
import { 
	fetchUserAttributes, 
	fetchAuthSession, 
	signIn, 
	updatePassword,
	signOut, 
	autoSignIn,
	confirmSignUp,
} from 'aws-amplify/auth'
import { CookieStorage, Hub } from 'aws-amplify/utils'
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito'
import cognito_config from './cognito_config.js'
import { EngineApi } from '../../main.jsx'

// import { createPaymentCustomer, subscribeToPlan } from '../payment/stripeSlice'


////////////////////////////////////////////////////////
// Amplify Auth Config
///////////////////////////////////////////////////////

Amplify.configure({
  Auth: {
    Cognito: {
		userPoolClientId: cognito_config.clientId,
		userPoolId: cognito_config.poolId
    }
  }
})

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage())

// Hub.listen('auth', ({ payload }) => {
// 	console.log('Listening for all messages: ', payload.event)
// 	switch (payload.event) {
// 		case 'signedIn':
// 		  console.log('user have been signedIn successfully.');
// 		  break;
// 		case 'signedOut':
// 		  console.log('user have been signedOut successfully.');
// 		  break;
// 		case 'tokenRefresh':
// 		  console.log('auth tokens have been refreshed.');
// 		  break;
// 		case 'tokenRefresh_failure':
// 		  console.log('failure while refreshing auth tokens.');
// 		  break;
// 		case 'signInWithRedirect':
// 		  console.log('signInWithRedirect API has successfully been resolved.');
// 		  break;
// 		case 'signInWithRedirect_failure':
// 		  console.log('failure while trying to resolve signInWithRedirect API.');
// 		  break;
//   }
// })

////////////////////////////////////////////////////////
// Fetch Current User
////////////////////////////////////////////////////////

export const fetchUser = createAsyncThunk(
	'user/fetch',
	async (payload, thunkAPI) => {
		// console.log('fetchUser thunk called')
		try {
			// If current user already exists, then don't fetch user again
			const currentUser = thunkAPI.getState().auth

			if (currentUser.username == undefined || (typeof currentUser.username == 'string' && currentUser.username != '')) {
				return currentUser
			}  else {

				const user = await fetchUserAttributes()
				const session = await fetchAuthSession()
				
				const newUser = {
					'username': user.email,
					'userId': user.sub,
					'isEmailVerified': user['email_verified'],
					// 'isActive': user['custom:isActive'] ? user['custom:isActive'] > 0 : false,
					// 'stripeId': user['custom:stripeId'],
					// 'subscription': user['custom:subscription'],
					'idToken': session.tokens.idToken.toString(),
					'accessToken': session.tokens.accessToken.toString()
				}

				return newUser
			}

		} catch (error) {
			if (error.payload.name != 'UserUnAuthenticatedException') {
				// console.log('fetch user: ', error)
				return thunkAPI.rejectWithValue(error)
			} else {
				// console.log('fetch user: ', error)
				return thunkAPI.rejectWithValue(error)
			}
		}
	}
)

////////////////////////////////////////////////////////
// Log-In User
////////////////////////////////////////////////////////

export const login = createAsyncThunk(
	'user/login',
	async ({ username, password }, thunkAPI) => {
		console.log('called login thunk')
		try {
			// Check if a User is Currently Logged In
			// If they are, then log them out
			await thunkAPI.dispatch(logout()).unwrap()
			// console.log('user has been logged out')


			// Login User
			const { nextStep } = await signIn({
				username: username,
				password: password,
			})

			// console.log('nextStep: ', nextStep)

			if (nextStep.signInStep == 'CONFIRM_SIGN_UP') {
				return {
					'username': username,
					'isAuthenticated': true,
					'isEmailVerified': false,
					'isActive': undefined
				}
			} else if (nextStep.signInStep == 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
				// console.log('CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED')
				return {
					'username': username,
					'isAuthenticated': false,
					'needsNewPassword': true,
					'isEmailVerified': false,
					'isActive': undefined
				}
			} else {
				const newUser = await fetchUserAttributes()
				const session = await fetchAuthSession()

				return {
					'username': username,
					'userId': newUser.sub,
					'isEmailVerified': true,
					'isAuthenticated': true,
					'isActive': newUser['custom:isActive'] > 0,
					'idToken': session.tokens.idToken.toString(),
					'accessToken': session.tokens.accessToken.toString()
					// 'stripeId': newUser['custom:stripeId'],
					// 'subscription': newUser['costom:subscription'],
				}
			}

		} catch (error) {
			// return thunkAPI.rejectWithValue(error)
			return thunkAPI.rejectWithValue({
				'source': 'login', 
				'name': error.name,
				'message': error.message
			})
		}
	}
)

////////////////////////////////////////////////////////
// Reset Password
////////////////////////////////////////////////////////
export const resetPassword = createAsyncThunk(
	'user/resetPassword',
	async ({ newPassword, code }, thunkAPI ) => {
		// console.log('reset password thunk')
		try {
			const user = thunkAPI.getState().auth

			// Login User
			const { nextStep } = await resetPassword({
				username: user.username,
				newPassword: newPassword,
				code: code,
			})

			// console.log(nextStep)

			return {
				'isAuthenticated': false,
				'isEmailVerified': false,
			}

		} catch (error) {
			return thunkAPI.rejectWithValue(error)
		}
	}
)

export const newPassword = createAsyncThunk(
	'user/updatePassword',
	async ({ oldPassword, newPassword }, thunkAPI ) => {
		// console.log('new password thunk')
		try {
			const user = thunkAPI.getState().auth

			// Login User
			const { nextStep } = await updatePassword({
				username: user.username,
				oldPassword: oldPassword,
				newPassword: newPassword,
			})

			// console.log(response)

			return {
				'isAuthenticated': false,
				'isEmailVerified': false,
			}

		} catch (error) {
			return thunkAPI.rejectWithValue(error)
		}
	}
)
////////////////////////////////////////////////////////
// Sign-Up New User
////////////////////////////////////////////////////////

export const signup = createAsyncThunk(
	'user/signup',
	async ({ name, organizationId, email, password, confirmPassword }, thunkAPI) => {

		try {
			let isSignUpComplete = false
			await EngineApi.post("/users", {
				username: email,
				password: password,
				organization_id: organizationId
			})
			.then((response) => {
				// console.log(response)
				isSignUpComplete = response.data.userConfirmed
			})
			.catch((error) => {
				if(error.response){
					console.error(error.response.data); // => the response payload 
					throw new Error(error.response.data)
				} else if (error.request) {
					console.error(error.request)
					throw new Error(error.request)
				} else {
					console.error(error.message)
					throw new Error(error.message)
				}
			})

			return {
				'username': email,
				// 'stripeId': 'XXXX',
				// 'subscription': 'XXXX',
				'isEmailVerified': isSignUpComplete,
				'isAuthenticated': false,
			}

		} catch (error) {
			return thunkAPI.rejectWithValue({
				'source': 'sign-up', 
				'name': error.name,
				'message': error.message
			})
		} 

	}
)

export const verifyEmail = createAsyncThunk(
	'user/verify',
	async ({ email, code, isSignUp = true}, thunkAPI) => {
		// console.log('verify email')
		try {

			const { isSignUpComplete, nextStep } = await confirmSignUp({
				username: email,
				confirmationCode: code,
			})

			if (nextStep.signUpStep == 'COMPLETE_AUTO_SIGN_IN') {

				// Check if a User is Currently Logged In
				// If they are, then log them out
				const currentUser = thunkAPI.getState().auth
				if (currentUser.username) {
					await thunkAPI.dispatch(logout()).unwrap()
				}

				await autoSignIn()
			}

			if (isSignUpComplete) {

				// Unfortunately amplify cannot handle auto sign-in after confirmEmail
				// Instead, users will be re-directed to sign-in page
				
				return {
					'username': email,
					'isEmailVerified': isSignUpComplete,
					'isAuthenticated': isSignUpComplete
				}
			}

		} catch (error) {
			return thunkAPI.rejectWithValue({
				'source': 'verification', 
				'name': error.name,
				'message': error.message
			})
		}
		
	}
)

////////////////////////////////////////////////////////
// Log-Out User
////////////////////////////////////////////////////////

export const logout = createAsyncThunk(
	'user/logout',
	async (payload, thunkAPI) => {
		try {
			await signOut()
			return {
				'username': '',
				'userId': undefined,
				'isEmailVerified': undefined,
				'isActive': undefined,
			}
		} catch (error) {
			return thunkAPI.rejectWithValue(error)
		}
	}
)

export const authSlice = createSlice({
	name: 'auth',
	initialState: {
		username: '', // Initially, no user is logged in
		userId: undefined,
		isActive: undefined,
		// stripeId: undefined,
		isEmailVerified: undefined,
		needsNewPassword: false,
		isAuthenticated: false,
		isLoading: false,
		session: undefined
	},
	reducers: {
		clearState: (state) => {
			state.username = '',
			state.userId = undefined,
			state.signInDetails = undefined,
			// state.stripeId = undefined,
			state.isEmailVerified = false,
			state.isAuthenticated = false,
			state.isLoading = false,
			state.session = undefined
            return state
        }
	},
	extraReducers: (builder) => {
		builder
			// Fetch Current User
			.addCase(fetchUser.fulfilled, ( state, action ) => {
				state = { ...state, ...action.payload }
				state.isAuthenticated = true
				state.isLoading = false
				return state
			})
			.addCase(fetchUser.pending, ( state, action ) => {
				state.isAuthenticated = false
				state.isLoading = true
				return state
			})
			.addCase(fetchUser.rejected, ( state, action ) => {
				state.username = '',
				state.userId = undefined,
				state.session = undefined,
				state.signInDetails = undefined,
				state.isAuthenticated = false,
				state.isLoading = false
				return state
			})
			// Log-In User
			.addCase(login.fulfilled, ( state, action ) => {
				// console.log('login fullfilled')
				state = { ...state, ...action.payload }
				state.isLoading = false
				// console.log('login state: ', state)
				return state
			})
			.addCase(login.rejected, ( state, action ) => {
				state.isAuthenticated = false
				state.progress = false
			})	
			.addCase(login.pending, ( state, action ) => {
				state.isLoading = true
				return state
			})	
			// Log-Out User
			.addCase(logout.fulfilled, ( state, action ) => {
				state = { ...state, ...action.payload }
				state.isLoading = false
				state.isAuthenticated = false 
				return state
			})
			.addCase(logout.rejected, ( state, action ) => {
				state.isLoading = false
				state.isAuthenticated = false
				return state
			})
			// Sign-Up New User
			.addCase(signup.fulfilled, ( state, action ) => {
				state = { ...state, ...action.payload }
				state.isLoading = false
				return state
			})
			.addCase(signup.pending, ( state, action ) => {
				state.isLoading = false
				return state
			})
			.addCase(signup.rejected, ( state, action ) => {
				state.isAuthenticated = false
				state.isLoading = false
				state.progress = false
			})
			.addCase(verifyEmail.fulfilled, ( state, action ) => {
				state = { ...state, ...action.payload }	
				return state
			})
			.addCase(verifyEmail.pending, ( state, action ) => {
				state.isEmailVerified = false
				state.isAuthenticated = false
				return state
			})
			.addCase(verifyEmail.rejected, ( state, action ) => {
				state.isEmailVerified = false
				state.isAuthenticated = false
				return state
			})
	},
})

// Action creators are generated for each case reducer function
export const { clearState } = authSlice.actions

export default authSlice.reducer
