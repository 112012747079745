import * as React from 'react'
import { 
	Avatar, 
	Button, 
	Box, 
	Checkbox,
	Container,
	CssBaseline,
	Divider,
	FormControlLabel,
	Grid,
	Link,
	MenuItem,
	Modal, 
	Select,
	Stack,
	styled,
	TextField,
	Typography
} from '@mui/material'

import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { createTheme, ThemeProvider } from '@mui/material/styles'

export const InfoContainer = () => {
	
	return (
		<Stack 
			direction='column' 
			justifyContent='space-between'
			height='100%'
			sx={{ 
				py: 5
			}}
		>
			<InfoTips />	
			{/* <SupportedBy /> */}
		</Stack>
	)
}

export const SupportedBy = () => {
	const supporters = [{}, {}, {}, {}]

	const supportorsContent = supporters.map((supporter, i) => {
		
		return (
			<Box 
				minWidth={20}
				height='auto'
				width='100%'
				key={i}
				sx={{ 
					bgcolor: 'grey',
					aspectRatio: 1,
				}}
			/>
		)
	})

	return (
		<Stack
			direction='column'
			justifyContent='center'
		>
		<Typography  
			variant="subtitle1"
			sx={{
				alignSelf: 'start',
					marginTop: 0,
					marginBottom: '16px',	
			}}
			>	
				Supported By:
			</Typography>

		<Stack 
			direction='row' 
			spacing={3}
			sx={{
				px: 4
			}}
		>
		{ supportorsContent }
		</Stack>
		</Stack>
	)
}

export const InfoTips = () => {

	const contentText = [
		{header: 'Production-Grade HPC Orchestration', body: 'Leverage powerful HPC tools to efficiently run complex calculations on your data sets.'},
		{header: 'Flexible Monthly Billing', body: 'Get billed based on your compute and data storage usage, ensuring you only pay for what you use.' },
		{header: 'Cancel Anytime', body: 'No long-term commitments. You can cancel your subscription at any time without any hassle.' }
	]

	const content = contentText.map((el, i) => {
		return (
			<Stack direction='row' spacing={2} key={i}>
				<CheckCircleIcon htmlColor='#4CAF50' sx={{ m: 1 }}/>
				<p> <strong> {el.header}: </strong> {el.body} </p>
			</Stack>
		)
	})
	
	return (
		<Stack 
			direction='column' 
			spacing={2}
			sx={{ paddingTop: '46px' }}
		>
			{ content }
		</Stack>
	)
}

