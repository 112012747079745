import React, { createContext, useContext, useState, useEffect} from 'react'

import { 
	Container,
	Stack, 
	Checkbox, 
	FormControlLabel 
} from '@mui/material'

import { theme } from './style/theme.jsx'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import { Outlet, Link } from 'react-router-dom'

import { Amplify } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'

import Navigation from './components/Navigation'
import { login, fetchUser } from './features/auth/authSlice'
import { AuthModal } from './components/Auth/Auth'

// import { loadStripe } from '@stripe/stripe-js'
// import { Elements } from '@stripe/react-stripe-js'
import { useDispatch, useSelector } from 'react-redux'

import config from './features/api/config'

// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css'

// Change Page Based on ENV variables, so that you don't have to click through every time
let startingOnSignup = false
if (process.env['IS_TESTING_AUTH'] && process.env['AUTH_START_PAGE'] === 'signup') {
	startingOnSignup = true
}

const App = () => {

	// Read User from Redux Store
	const [ isLoading, setIsLoading] = useState(true)	
	// const stripeId = useSelector((state) => state.auth.stripeId)
	const isActive = useSelector((state) => state.auth.isActive)

	const components = {
		Header() {
			return (
				<div style={{display: "flex", padding: 20}}>	
					<a id="logo" alt="ET Logo"/>	
				</div>
			)
		}
	}

	return (	
		<ThemeProvider theme={theme}>
			<Navigation isActive={isActive} />
			<AuthModal isSignIn={!startingOnSignup} />
			<Container id="detail" fixed sx={{ marginTop: '56px' }}>
				<Outlet />
			</Container>
		</ThemeProvider>
	)
}


export default App

