import React from 'react'
import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import './style/index.css'
import App from './App'

import { Provider } from 'react-redux'
import { injectStore } from './features/payment/'

import config from './features/api/config'

import Home from './pages/Home'
import Filesystems from './pages/Filesystems'
import Tools from './pages/Tools'
import Keys from './pages/Keys'
import Batches from './pages/Batches'
import Viewer from './pages/Viewer'
import Support from './pages/Support'
import AccountSettings from './pages/settings/Account'

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"

import setupAxios from './store/setupAxios'
import axios from 'axios'
import { store } from './store/store'

injectStore(store)
export const EngineApi = axios.create({
	baseURL: config.apiEndpoint,
	params: {}
})
setupAxios(EngineApi, store)


const Error404 = () => {
	return <h2>404 Not found</h2>
}

const metadata = (content) => content

const router = createBrowserRouter([
	{
		path: "/",
		element: <App/>,
		errorElement: <Error404/>,	
		children: [
			{
				path: "/",
				id: 'home',
				element: <Home/>,
			},
			{
				path: "filesystems",
				id: 'filesystems', 
				element: <Filesystems/>,
			},
			{
				path: "tools",
				id: 'tools',
				element: <Tools/>
			},
			{
				path: "keys",
				id: 'keys',
				element: <Keys/>
			},
			{
				path: "batches",
				id: 'batches',
				element: <Batches/>
			},
			{
				path: "viewer",
				id: 'viewer',
				element: <Viewer/>
			},
			{
				path: "support",
				id: 'support', 
				element: <Support/>
			},
			{
				path: 'account/',
				id: 'account',
				key: 'account',
				element: <AccountSettings />
			},
		]
  	}
])

const root = createRoot(document.getElementById('root'))

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<RouterProvider router={router} />
		</Provider>
	</React.StrictMode>	
)
