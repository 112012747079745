import React, {useState} from "react"
import {
	Box, 
	Grid2 as Grid,
	Typography,
    Tabs,
    Tab,
    AppBar
} from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '../style/theme';
import Page from "./Page"


const GettingStartedTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}


const GettingStartedApplications = () => {
    return (
        <Grid>
            <Typography fontWeight="bold">
                Use ExploreTech's Pre-Built Mineral Exploration Tools:
            </Typography>
            <Typography>
                Step 1. Create a filesystem
            </Typography>
            <Typography>
                Step 2. Upload your data
            </Typography>
            <Typography>
                Step 3. Launch a job from the Tools page
            </Typography>
        </Grid>
    )
}


const GettingStartedDevelopers = () => {
    return (
        <Grid>
            <Typography fontWeight="bold">
                Start Creating HPC Applications Today:
            </Typography>
            <Typography>
                Step 1. Create an API Key
            </Typography>
            <Typography>
                Step 2. Create a filesystem
            </Typography>
            <Typography>
                Step 3. Create & push your first tool using the Engine CLI
            </Typography>
            <Typography>
                Step 4. Launch a batch job from the Python API 
            </Typography>
        </Grid>
    )
}


const Home = () => {

    const [page, setPage] = useState(0)

    return (
        <Page name={'Home'}>
            <ThemeProvider theme={theme}>
                <Grid container direction="column" sx={{height: "100%"}}>
                    <Grid size="auto">
                        <Typography variant="h4" sx={{mb: 1}}>
                            Welcome to The ExploreTech Engine
                        </Typography>
                        <Typography variant="subtitle1" sx={{mb: 3}}>
                            The High-Performance Computing Platform for engineers & scientists.
                        </Typography>
                    </Grid>
                    <Grid size="auto">
                        <Typography variant="h5" sx={{mb: 3}}>
                            Get Started:
                        </Typography>
                    </Grid>
                    <Grid size="grow" sx={{width: "100%"}}>
                        <Box sx={{
                            backgroundColor: "#F2F2F2", 
                            height: "100%",
                            borderRadius: "5px"
                            // pl: 5
                        }}>
                            <AppBar position="static" color="inherit">
                                <Tabs 
                                    value={page} 
                                    variant="fullWidth"
                                    onChange={(e, newPage) => setPage(newPage)}
                                >
                                    <Tab label="Developers"/>
                                    <Tab label="[Coming Soon!] Inverter/Driller/Mapper"/>
                                </Tabs>
                            </AppBar>
                            <GettingStartedTabPanel value={page} index={0}>
                                <GettingStartedDevelopers/>
                            </GettingStartedTabPanel>
                            <GettingStartedTabPanel value={page} index={1}>
                                <GettingStartedApplications/>
                            </GettingStartedTabPanel>
                        </Box>
                    </Grid>
                    <Grid size="grow" sx={{width: "100%"}}>
                        <Typography variant="subtitle1" sx={{mt: 3}}>
                            Resources
                        </Typography>
                        <Typography>
                            - How The Engine works
                        </Typography>
                        <Typography>
                            - Tutorials
                        </Typography>
                        <Typography>
                            - Python API documentation
                        </Typography>
                        <Typography>
                            - CLI documentation
                        </Typography>
                        <Typography>
                            - About ExploreTech
                        </Typography>
                        <Typography>
                            - [COMING SOON!] Using Inverter, Driller, & Mapper
                        </Typography>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </Page>
    )
}


export default Home