import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Collapse,
	Box,
	Button,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	ListItem,
	ListItemButton,
	MenuItem,
	Modal,
	Select,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	Paper,
} from '@mui/material'

import {
	Delete as DeleteIcon,
	KeyboardArrowDown as ArrowDownIcon,
	KeyboardArrowUp as ArrowUpIcon,
} from '@mui/icons-material'

import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles'
import { theme } from '../style/theme.jsx'
import { EngineAPI } from '../features/api/config'
import './Auth/Auth.css'

const TaskModal = ({ task, open, setOpen }) => {

	const [listOfFilesystems, setListOfFilesystems] = React.useState([])
	const [filesystem, setFilesystem] = React.useState('')
	const [cpu, setCPU] = React.useState('4vCPU')
	const [memory, setMemory] = React.useState('8GB')
	const [listOfArgs, setListOfArgs] = React.useState([])
	
	React.useEffect(() => {
		EngineAPI.fetchResource('filesystems').then(response => {
			setListOfFilesystems(response)
		})
	}, [task['id']])
	
	const handleSubmit = () => {
		//EngineAPI.postTask({ 'taskId': task['id'], 'filesystemId': , 'arg':, 'cpu':, 'memory': })
		
	}

	const handleNewArg = (arg, index) => {
		let args = listOfArgs
		args[index] = arg
		setListOfArgs(args)
		console.log(listOfArgs)
	}

	const deleteArg = (e, index) => {
		console.log(deleteArg)
		let args = listOfArgs.splice(index, 1)
		console.log(listOfArgs)
		setListOfArgs(args)
		console.log(listOfArgs)
	}


	return (
		<Modal
			open={open}
			onClose={(e) => setOpen(e)}
		> 
			<Box
				className='modal-container'	
				sx={{
					'padding': '10px',
					'width': '400px',
					'& .MuiTextField-root': { my: 1 },
				}}
			> 
				<Stack
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>

				{ /* Header */ }
				<h3> 
					<div> Launch a New Task for </div>
					<div style={{ 		
						textAlign: 'center',
					}}> { task['data']['name'] } </div>
				</h3>
				
				{ /* Select Filesystem */ }
				{ listOfFilesystems && 
				<FormControl fullWidth>
					<InputLabel> Filesystem: </InputLabel>
					<Select
						value={filesystem}
						label="Filesystem"
						onChange={(e) => setFilesystem(e.target.value)}
					>
						{ 
							listOfFilesystems.map(f => {
								return (
									<MenuItem value={f['filesystem_id']}>{f['filesystem_name']}</MenuItem>
								)
							})
						}	
					</Select>
				</FormControl>
				}

				<FormControl fullWidth>
					<TextField 
						label='Memory'
						variant='outlined'
						value={memory}
					>

					</TextField>
				</FormControl>
		
				<FormControl fullWidth>
					<TextField 
						label='CPU'
						variant='outlined'
						value={cpu}
					>

					</TextField>
				</FormControl>

				<FormControl fullWidth>
					<Button 
						variant='outlined'
						onClick={() => setListOfArgs(listOfArgs.concat(['']))}
					>Add Argument</Button>
					{ 
						listOfArgs.map((arg, index) => {
						
							return (
								<TextField 
									key={listOfArgs[index]}
									label='CPU'
									variant='outlined'
									onChange={(e) => handleNewArg(e.target.value, index)}
									slotProps={{
										input:{
											startAdornment: <IconButton
												aria-label={'delete argument'}
												onClick={(e) => deleteArg(e, index)}
												edge="end"
											>
												<DeleteIcon />
											</IconButton>,
										},
									}}
								/>
							)
						})
					}
				</FormControl>

				</Stack>

			</Box>
		</Modal>
	)
}

function Row({ row, modal, onSelected }) {
	const [open, setOpen] = React.useState(false)
	const [contents, setContents] = React.useState([])
	const [showModal, setShowModal] = React.useState(false)

	const authToken = useSelector((state) => state.auth.authToken)

	
	// Reformat Files
	const handleOpen = async (e) => {
		e.stopPropagation()
		console.log('open')
		const list = await EngineAPI.fetchResourceContents( row )
			.then(contents => {
				let directories = contents['directories']
					.map(name => { return { 
						'data': {
							'name': name, 
						},
						'type': 'directory', 
						'resource': 'filesystems', 
						'id': row.id,
						'path': row.path + name + '/'
					}})
				let files = contents['files']
					.map(name => { return {  
						'data': {
							'name': name, 
						},
						'type': 'file',
						'resource': 'filesystems', 
						'id': row.id,
						'path': row.path + name + '/'
					}})
				return directories.concat(files)
			})
		
		setContents(list)	
		setOpen(!open)
	}

	const handleRowClick = () => {
		console.log(row, modal)
		setShowModal(true)
		
		//EngineAPI.selectResourceItem(row)	
	}

	return (
		<React.Fragment>	
			<ThemeProvider theme={theme}>
			<TableRow 
				onClick={handleRowClick}
				sx={{ 
					'& > *': { borderBottom: 'unset' },
					// hover effect is visible, unless you are hovering over the 
					// expand button
					'&:hover:not(:has(.expandBtn:hover))': {
						textDecoration: 'none',
						backgroundColor: (theme.vars || theme).palette.action.hover,
						// Reset on touch devices, it doesn't add specificity
						'@media (hover: none)': {
							backgroundColor: 'transparent',
						},
					}
				}}
			>	
				{ 
					Object.values(row.data).map((value, index) => {
						return (
							<TableCell 
								key={`${value}-${index}`} 
								size='small' 
								padding='none' 
								align='left' 
								component="th" 
								scope="row"
							>		
							<Stack direction="row">
								{ /* Arrow Visible if the row is a folder */ }
								{ index == 0 && (row.type == 'directory' || row.type == 'filesystem') ? 

										<IconButton
											className='expandBtn'
											aria-label="expand row"
											size="small"
											onClick={handleOpen}
										>
											{ open ? <ArrowUpIcon /> : <ArrowDownIcon /> }
										</IconButton>

								: 
									<></>
								}
								{ /* File Label */ }
								<ListItem> { value } </ListItem>
							</Stack>
							</TableCell>
						)
					})
				}

			</TableRow>
			
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
				<Box>
				<Collapse in={open} timeout="auto" unmountOnExit>
					<Table>
						<CollapsibleTable data={contents}  />
					</Table>
				</Collapse>
				</Box>
				</TableCell>
			</TableRow>
			</ThemeProvider>
			{
				<TaskModal task={row} open={showModal} setOpen={() => setShowModal(false)} />
			}
		</React.Fragment>
	)
}

const CollapsibleTable = ({ data, modal  }) => {


	return (

			<TableBody>
				{data.map((row) => (
					<Row key={row.data.name} row={row} modal={modal}/>
				))}
			</TableBody>

	)
}

export default function CollapsibleTableContainer({ data, modal }) {
	const headers = data.reduce((accumulator, currentValue) => {
		return [...new Set([...accumulator, ...Object.keys(currentValue['data'])])]
	}, [])

	return (
		<TableContainer component={Paper}>
			<Table>
			<TableHead>
				<TableRow>
					{ headers.map((header) => (
						<TableCell key={header} sx={{ 'textTransform': 'capitalize' }}>{header}</TableCell>
					))}
				</TableRow>
			</TableHead>
				<CollapsibleTable data={data} modal={modal}/>
			</Table>
		</TableContainer>
	)
}

