import React, { createContext, useContext, useState, useEffect} from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { 
	AppBar,
	Box,
	CssBaseline,
	Icon,
	IconButton,
	Toolbar,
	Typography,
} from '@mui/material'

import { 
	Mail as MailIcon,
	Menu as MenuIcon,
	Home as HomeIcon,
	Person as UserIcon,
	TaskAlt as TaskIcon,
	Folder as FolderIcon,
	Build as ToolsIcon,
	FindInPage as PageViewIcon,
	Visibility as VisibilityIcon,
	Key as APIKeysIcon,
	Lock as LockIcon,
	Login as LoginIcon,
	Logout as LogoutIcon,
	Settings as GearIcon,
	LibraryBooks as LibraryBooksIcon

} from '@mui/icons-material'


import { Sidebar, NavItem, Topbar } from './Sidebar'

export const Navigation = ({ isActive }) => {
	// Set variables
	const drawerWidth = 240
	
	// Get access to Current User Details
	const username = useSelector((state) => state.auth.username, shallowEqual)
	const userId = useSelector((state) => state.auth.userId, shallowEqual)

	const [isOpen, setIsOpen] = useState(false)

	const dispatch = useDispatch()
	const onLogout = async (data) => {
		await dispatch(logout())
	}

	const handleOpenClose = (state) => {
		if (state) {
			setIsOpen(state) 
		} else {
			setIsOpen(!isOpen)
		}
	}

	//////////////////////////////////////////////////
	// Defines the structure of the Nav Menu
	//////////////////////////////////////////////////
	const content = {
		top: [
			{
				primary: 'Home',
				link: '/',
				icon:  <Icon component={HomeIcon} />,
				sx: {
					bgcolor: 'secondary.light',
				},
				iconSx: {
					color: 'secondary.main'
				},
				isExpandedOnStart: true,
				children: [
					{
						primary: 'Filesystems',
						link: 'filesystems',
					},
					{
						primary: 'Tools',
						link: 'tools'
					},
					{
						primary: 'Batches',
						link: 'batches',
					}
				],		
			},

			{
				primary: 'Viewer (beta)',
				link: 'viewer',
				icon:  <Icon component={VisibilityIcon} />,
			},
			{
				primary: 'API Keys',
				link: 'keys',
				icon:  <Icon component={LockIcon} />,
			}

		],
		bottom: [
			{
				primary: 'Documentation',
				link: 'https://docs.exploretech.ai',
				icon: <Icon component={LibraryBooksIcon} />,
			},
			{
				primary: 'Support',
				link: 'support',
				icon: <Icon component={GearIcon} />,
			},
			{
				primary: username,
				secondary: userId,
				link: 'account',
				icon: <Icon component={UserIcon} />,
				iconRight: <Icon 
					component={LogoutIcon} onClick={() => onLogout() }
				/>,
			}
		],
	}

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar
				position="fixed"
				color='white'
				sx={{
					width: { sm: `calc(100% - ${drawerWidth}px)` },
					ml: { sm: `${drawerWidth}px` },
					boxShadow: 'none',	
				}}
			>
				<Topbar
					sidebarContent={content}
					sx={{ backgroundColor: 'white', }}
				>
					<IconButton
						color="white"
						aria-label="open drawer"
						edge="start"
						onClick={() => setIsOpen(!isOpen)}
						sx={{ mr: 2, display: { sm: 'none' } }}
					>	
						<MenuIcon />
					</IconButton>
				</Topbar>
			</AppBar>
		
			<Sidebar 
				content={content}
				isOpen={isOpen} 
				width={drawerWidth}
				onClose={() => setIsOpen(false)} 
			/>
		</Box>
	)

}

export default Navigation

