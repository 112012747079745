import React from "react"
import { useState, useEffect } from "react"
import Page from "./Page"

import { EngineApi } from "../main";
import { useSelector } from "react-redux";
import { 
	Button,
    IconButton,
	Box, 
	Grid2 as Grid,
	Typography,
	Modal,
	List,
    ListItem,
    ListItemText,
    ListItemIcon,
    TextField,
    Alert
} from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '../style/theme';

import DeleteIcon from '@mui/icons-material/Delete';
import KeyIcon from '@mui/icons-material/Key';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {
    Close as CloseIcon,
} from '@mui/icons-material'


class Key {
    constructor(keyId, name, date, id) {
        this.name = name
        this.date = date
        this.id = id // NOTE: this is not the key, this is only for the purposes 
        this.keyId = keyId
    }

    async delete() {

        const response = await EngineApi.delete(
            `/keys/${this.keyId}`
        )
        .then((response) => {
            return {success: true, error: null}
        })
        .catch((error) => {
            return {success: false, errorMessage: error}
        })

        return response
    }
}


const DeleteKeyConfirmation = ({open, setOpen, apiKey, updateList}) => {

    const [error, setError] = useState(null)

    const handleRemoveItem = async (e) => {
        const {success, errorMessage} = await apiKey.delete()
        if (success) {
            updateList(l => l.filter(item => item.id !== apiKey.id));
        } else {
            setError(errorMessage)
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Modal 
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='modal-container'>
                    <IconButton onClick={() => setOpen(false)} sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8
					}}>
                        <CloseIcon/>
                    </IconButton>
                    <Grid 
                        container 
                        spacing={5}
                        direction="column"
                        sx={{alignItems: "center", p:10}}
                    >
                        <Grid>
                            <Typography component={'span'} variant="h5" align="center" sx={{fontWeight: "bold"}}>
                                <Box>
                                    Are you sure you want to delete "{apiKey.name}"?
                                </Box>
                            </Typography>
                        </Grid> 
                        <Grid>
                            <Typography component={'span'} align="center">
                                <Box>
                                    You will not be able to undo this action. Don't sweat though, you can always create a new API key.
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid container>
                            <Button onClick={() => setOpen(false)}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={handleRemoveItem}>
                                Delete
                            </Button>
                        </Grid>
                        {error && <Alert severity="error">{error}</Alert>}
                    </Grid>
                </Box>
            </Modal>
        </ThemeProvider>
    )
}


const KeyItem = ({apiKey, updateList}) =>{

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    return(
        <ListItem sx={{boxShadow: 2, mb: 5}} variant="apikey" secondaryAction={
            <IconButton onClick={() => setDeleteModalOpen(true)}>
                <DeleteIcon/>
            </IconButton>
        }>
            <ListItemIcon>
                <KeyIcon/>
            </ListItemIcon>
            <ListItemText primary={apiKey.name} secondary={"Date Created: " + apiKey.date}/>
            {deleteModalOpen && <DeleteKeyConfirmation open={deleteModalOpen} setOpen={setDeleteModalOpen} apiKey={apiKey} updateList={updateList}/>}
        </ListItem>
    )
}


const IssuedKey = ({apiKey}) => {
    
    const copyKey = () => {
		navigator.clipboard.writeText(apiKey).then(
            () => {},
            () => {
                console.error('Failed to copy');
            }
        );
	}

    return (
        <Grid 
            container 
            spacing={5}
            direction="column"
            sx={{alignItems: "center", p:10}}
        >
            <Grid>
                <Typography component={'span'} variant="h5" align="center" sx={{fontWeight: "bold"}}>
                    <Box>
                        Here's Your New API Key:
                    </Box>
                </Typography>
            </Grid> 
            <Grid>
                <Typography component={'span'} align="center">
                    <Box>
                        Make sure to save this in a secure location. Once you close this page you will not be able to see this key again.
                    </Box>
                </Typography>
            </Grid> 
            <Grid sx={{border: "1px solid grey", borderRadius: "5px", p:5}}>
                <Typography component={'span'} align="center" sx={{wordBreak: "break-word" }}>
                    <Box>
                        {apiKey}
                    </Box>
                </Typography>
            </Grid>      
            <Grid>
                <Box>
                    Copy to clipboard:
                    <IconButton onClick={copyKey}>
                        <ContentCopyIcon fontSize="small"/>
                    </IconButton>
                </Box>
            </Grid>       
        </Grid>
    )
}


const NewKeyForm = ({setApiKey}) => {

    const [formValid, setFormValid] = useState(false)
    const [error, setError] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        description: ''
    });

    const updateForm = (name, event) => {
        const newFormData = {...formData, [name]: event.target.value}
        setFormData(newFormData)

        if (newFormData.name !== '' && newFormData.description !== '') {
            setFormValid(true)
        } else {
            setFormValid(false)
        }
    }

    const createNewKey = async (event) => {
        event.preventDefault()

        EngineApi.post("/keys", {
            name: formData.name,
            description: formData.description
        }).then((response) => {
            setApiKey(response.data.apiKey)
        }).catch((error) => {
            if (error.response) {
                setError(error.response.data)
            } else {
                setError('An unknown error occurred. Please try again.')
            }
        })
    }

    return (
        <Grid 
            container 
            spacing={2}
            direction="column"
            sx={{justifyContent: "center", alignItems: "center", p:10}}
        >                
            <Grid>
                <Typography component={'span'} variant="h5" sx={{fontWeight: "bold"}}>
                    <Box>
                        Create a New API Key
                    </Box>
                </Typography>
            </Grid>
            <Grid>
                <TextField 
                    required
                    id="key-name" 
                    label="API Key Name"
                    value={formData.name} 
                    onChange={(e) => updateForm("name", e)}
                />
            </Grid>
            <Grid>
                <TextField 
                    required
                    multiline
                    rows={4}
                    id="key-description" 
                    label="Description"
                    value={formData.description} 
                    sx={{p: 0}}
                    onChange={(e) => updateForm("description", e)}
                />
            </Grid>
            <Button 
                disabled={!formValid} 
                variant="contained" 
                onClick={createNewKey}
            >
                Generate API Key
            </Button>
            {error && <Alert severity="error">{error}</Alert>}
        </Grid>
    )
}


const NewKeyModal = ({open, setOpen}) => {

    const [apiKey, setApiKey] = useState(null)

    const closeModal = () => {
        setApiKey(false)
        setOpen(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <Modal 
                open={open}
                onClose={() => setOpen(true)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='modal-container'>
                    <IconButton onClick={closeModal} sx={{
							position: 'absolute',
							right: 8,
							top: 8
					}}>
                        <CloseIcon/>
                    </IconButton>
                    { apiKey ? <IssuedKey setModalOpen={setOpen} apiKey={apiKey}/> : <NewKeyForm setApiKey={setApiKey}/> }
                </Box>
            </Modal>
        </ThemeProvider>
    )
}
  

const Keys = () => {

    const [APIKeys, setAPIKeys] = useState([])
    const [newKeyModelOpen, setNewKeyModalOpen] = useState(false);
    
    const idToken = useSelector((state) => state.auth.idToken)

    const fetchData = async () => {
        await EngineApi.get(
            "/keys"
        )
        .then((response) => {
            const newAPIKeys = []
            let nextKeyID = 0
            for (const item of response.data){
                newAPIKeys.push(new Key(item.key_id, item.name, item.dateCreated, nextKeyID))
                nextKeyID += 1
            }
            setAPIKeys(newAPIKeys)
        })
        .catch((error) => {
            console.error(error)
        })
    }

    useEffect(() => {
        if (idToken){
            fetchData()
        }
    }, [idToken, newKeyModelOpen])

    return(
        <Page name={'API Keys'}>
            <ThemeProvider theme={theme}>
            <Grid container direction="row" spacing={12} sx={{
                mt:5, 
                mb:5,
            }}>
                <Grid size={"grow"}>
                    <Typography variant="h4" component={'span'} fontWeight="bold">
                        <Box>
                            My Keys
                        </Box>
                    </Typography>
                </Grid>
                <Grid size={"auto"}>
                    <Button onClick={() => setNewKeyModalOpen(true)}>
                        + New
                    </Button>
                </Grid>
            </Grid>
            <Grid container direction="column" xs={12}>
                <List sx={{
                    overflow: "auto",
                    p: 2
                }}>
                    {APIKeys.map((item, index) => <KeyItem key={index} apiKey={item} updateList={setAPIKeys}/>)}
                </List>
            </Grid>
            {newKeyModelOpen && <NewKeyModal open={newKeyModelOpen} setOpen={setNewKeyModalOpen}/>}
            </ThemeProvider>
        </Page>
    )
}


export default Keys
