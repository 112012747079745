// import * as Stripe from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import axios from 'axios'

const stripeKey = process.env['STRIPE_PUBLIC_KEY']

const stripeEndpoint = process.env['PAYMENT_API_ENDPOINT']

export const apiInstance = axios.create()

let store 
export const injectStore = _store => {
  store = _store
}

apiInstance.interceptors.request.use(config => {
	config.headers.authorization = store.getState().auth.authToken
	return config
})

let stripe, customer, price, card

let priceInfo = {
	basic: {
		amount: '500',
		name: 'Basic',
		interval: 'monthly',
		currency: 'USD',
	},
	premium: {
		amount: '7500',
		name: 'Premium',
		interval: 'monthly',
		currency: 'USD',
	},
}

class Payment {
	constructor () {
		this.axios = apiInstance
		this.url = stripeEndpoint
	}
	
	// Fetch Setup Keys
	fetchSetupKeys( customerId ) {
		return new Promise( (resolve, reject) => {
			this.axios.request(`${this.url}/payments/secrets`, {
				method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},

			}).then(({ data }) => {
				resolve({ 
					'clientSecret': data.clientSecret, 
					'ephemeralKey': data.ephemeralKey 
				})
			}).catch((error) => {
				reject(error)
			})
		})
	}

	// Function call to create a stripe user
	createCustomer( email, cognitoId ) {
		const payload = {
			email: email,
			/*metadata: {
				cognitoId: cognitoId,
			},
			*/
		}
		console.log(payload)
		return new Promise( (resolve, reject) => {
			this.axios.request(`${this.url}/payments/customers`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				data: JSON.stringify(payload),
			}).then((response) => { 
				console.log(response['data'])
				resolve(response['data'])
			})
			.catch((error) => {
				reject(error)
			})
		})
	}

	// Create Subscription
	createSubscription(customerId) {
		return new Promise((resolve, reject) => {
			this.axios.request(`${this.url}/payments/subscriptions`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				data: {
					customerId: customerId,
				},
			}).then((response) => {
				return response.json()
			}).then((formattedResult) => {
				resolve(formattedResult)
			})
			.catch((error) => {
				reject(error)
			})
		})
	}

	// Fetch Customer's default Payment
	fetchPaymentMethod(customerId) {
		return new Promise((resolve, reject) => {
			this.axios.request(`${this.url}/payments/methods`, {
				method: 'get',
				headers: {
					'Content-type': 'application/json',
				},
			})
				.then((response) => {
					resolve(response.data)
				})
				.catch((error) => {
					reject(error)
				})
		})

	
	}

	// Fetch Upcoming Invoice
	fetchInvoice(customerId){
		return new Promise((resolve, reject) => {
			this.axios.request(`${this.url}/payments/invoices`, {
				method: 'get',
				headers: {
					'Content-type': 'application/json',
				}
			})
				.then((response) => {
					resolve(response.data)
				})
				.catch((error) => {
					reject(error)
				})

		})
	}

	cancelSubscription(subscription){
		return new Promise((resolve, reject) => {
			return this.axios.request(`${this.url}/payments/subscriptions`, {
				method: 'delete',
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then((response) => {
					resolve(response.data)
				})
				.catch((error) => {
					reject(error)
				})
		})
	}

	// Format price details and detect zero decimal currencies
	getFormattedAmount(amount){
		var amount = amount
		var numberFormat = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			currencyDisplay: 'symbol',
		})
		var parts = numberFormat.format(amount)
		return parts
	}
}

const PaymentAPI = new Payment()
export default PaymentAPI

export const getConfig = () => {
	return fetch('/config', {
		method: 'get',
		headers: {
			'Content-Type': 'application/json',
		},
	})
    .then((response) => {
		return response.json()
    })
    .then((response) => {
		// Set up Stripe Elements
		stripeElements(response.publishableKey)
	})
}

/* ------ Sample helpers ------- */

const capitalizeFirstLetter = (string) => {
	let tempString = string.toLowerCase()
	return tempString.charAt(0).toUpperCase() + tempString.slice(1)
}

export const getDateStringFromUnixTimestamp = (date) => {
	let nextPaymentAttemptDate = new Date(date * 1000)
	let day = nextPaymentAttemptDate.getDate()
	let month = nextPaymentAttemptDate.getMonth() + 1
	let year = nextPaymentAttemptDate.getFullYear()

	return month + '/' + day + '/' + year
}

