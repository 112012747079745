import { Filesystem } from "./filesystems"


export class Hardware {
    constructor(filesystemList, cpu, memory) {
        this.filesystemList = filesystemList
        this.cpu = cpu
        this.memory = memory
    }


    isEqual(obj) {
        try {
            let isEqual = true

            isEqual = isEqual && (this.cpu === obj.cpu)
            isEqual = isEqual && (this.memory === obj.memory)
            
            for (const [i, fs] of this.filesystemList) {
                isEqual = isEqual && (fs === obj.filesystemList[i])
            }
            return isEqual

        } catch {

        }
    }


    toJson() {
        const hardwareJson = {
            filesystem_list: this.filesystemList.map((fs) => fs.toJson()),
            cpu: this.cpu,
            memory: this.memory
        }
        return hardwareJson
    }


    static fromJson(hardwareJson) {
        const hardware = new Hardware(
            hardwareJson.filesystem_list.map((fs) => Filesystem.fromJson(fs)),
            hardwareJson.cpu,
            hardwareJson.memory
        )
        return hardware
    }
}


export class Tool {

    constructor(toolId, toolName, toolDescription) {
        this.toolId = toolId
        this.toolName = toolName
        this.toolDescription = toolDescription
    }


    isEqual(obj) {
        try {
            let isEqual = true
            isEqual = isEqual && (this.toolId === obj.toolId)
            isEqual = isEqual && (this.toolName === obj.toolName)
            isEqual = isEqual && (this.toolDescription === obj.toolDescription)
            return isEqual

        } catch {
            return false
        }
    }


    toJson() {
        const toolJson = {
            tool_id: this.toolId,
            tool_name: this.toolName,
            tool_description: this.toolDescription
        }
        return toolJson
    }


    static fromJson(toolJson) {
        const tool = new Tool(
            toolJson.tool_id,
            toolJson.tool_name,
            toolJson.tool_description
        )
        return tool
    }    
}