import React, {useState, useEffect, act} from "react";
import { fetchAuthSession } from '@aws-amplify/auth';
import Navbar from "../components/Navbar";
//import Directory from "../components/Directory"
import Table from '../components/ResourceTable'
import './Filesystems.css'
import Page from "./Page";
import FilesDragAndDrop from "../components/FilesDragAndDrop"
import config, { EngineAPI } from "../features/api/config"

class VFS {
    constructor(name, id) {
        this.name = name
        this.id = id
        this.resource = "filesystems"
    }
}

const NewVfsForm = ({idToken, setModalOpen, setLoading}) => {

    const [formData, setFormData] = useState({
        name: '',
        description: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name.slice(4)]: value });
    };

    const createNewVfs = async (event) => {
        event.preventDefault()
        setLoading(true)

        await fetch(
            config.apiEndpoint + "/filesystems", {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + idToken
                },
                body: JSON.stringify({
                    name: formData.name,
                    description: formData.description
                })
            }
        ).then(response => {
            if (response.ok) {
                if (response.status == 200) {
                    throw Error('already exists')
                } else {
                    return response.json()
                }
            } else {
                throw new Error(response.json())
            }
        }).then(newVfs => {
            console.log('new filesystem', newVfs)
        }).catch(err => {
            console.log('could not create filesystem', err)
        }).finally(() => {
            setModalOpen(false)
            setLoading(false)
        })
    }

    return (
        <>
            <h3>Create a new Filesystem</h3>
            <form onSubmit={async (e) => await createNewVfs(e)}>
                <label htmlFor="vfs-name">Name:</label><br/>
                <input type="text" id="vfs-name" name="vfs-name" value={formData.name} onChange={handleChange} required/><br/>
                
                <br/>
                
                <label htmlFor="vfs-description">Description (Optional):</label><br/>
                <textarea id="vfs-description" name="vfs-description" rows="4" value={formData.description} onChange={handleChange} /><br/>
                
                <input type="submit" value="Submit"/>
            </form>
        </> 
    )
}

const Modal = ({setModalOpen, idToken}) => {

    const [loading, setLoading] = useState(false)

    return (
      <div className="modal">
        <div className="modal-content">
          <span className="close" onClick={() => setModalOpen(false)}>&times;</span>
          {loading ? 
            <div>Creating new filesystem... </div>
            :
            <NewVfsForm idToken={idToken} setModalOpen={setModalOpen} setLoading={setLoading}/>
          }
        </div>
      </div>
    );
  }

const Filesystems = () => {

    const [idToken, setIdToken] = useState(null)
    const [activeVFS, setActiveVFS] = useState(new VFS(null, null))
    const [vfsData, setVfsData] = useState([])
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(true)
    const [filesLoading, setFilesLoading] = useState(true)
    const [path, setPath] = useState(['.'])

    const fetchToken = async () => {
        let session = null
        try {
            session = await fetchAuthSession()  // Fetch the authentication session
        } catch (err) {
            console.log(err)
        }
        setIdToken(session.tokens.idToken.toString())
    }

    const fetchFilesystems = async () => {

        if (idToken) {
			const data = await EngineAPI.fetchResource('filesystems').then(response => {
				const formatted = response.map(filesystem => {
					return {
						'id': filesystem['filesystem_id'],
						'data': {
							'name': filesystem['filesystem_name'],
						},
						'resource': 'filesystems',
						'type': 'filesystem',
						'path': '/',
					}
				})
				setVfsData(formatted)
			})

        }
    };

    useEffect(() => {
        fetchToken()
        fetchFilesystems()
    }, [idToken])
	
	const handleClick = (v) => {
		console.log(v)
		setModalOpen(true)
	}

    return (
        <Page name="Filesystems">
            <span id="vfs-header">
                <h2>Available Filesystems</h2> 
                <button onClick={() => setModalOpen(true)}>+ New</button>
            </span>
			{
				<Table data={vfsData} />
            }
            {modalOpen && <Modal setModalOpen={setModalOpen} idToken={idToken}/>}
        </Page>
    )
}

export default Filesystems
