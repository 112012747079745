import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
// import paymentReducer from '../features/payment/stripeSlice'

export const store = configureStore({
	reducer: {
		auth: authReducer,
		// payment: paymentReducer,
	},
})

export default store
