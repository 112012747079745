import React, { createContext, useContext, useState, useEffect, cloneElement} from 'react'

import { 
	Avatar,
	Alert,
	Button, 
	Box, 
	Checkbox,
	Container,
	CssBaseline,
	Divider,
	FormControlLabel,
	Grid2 as Grid,
	Icon,
	IconButton,
	Link,
	MenuItem,
	Modal, 
	Select,
	Stack,
	styled,
	TextField,
	Typography,
	useMediaQuery
} from '@mui/material'

import {
	Close as CloseIcon,
} from '@mui/icons-material'

import { blue } from '@mui/material/colors'


import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles'
import { theme } from '../../style/theme.jsx'

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'

import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { SignUpSchema, ResetPasswordSchema } from '../../features/auth/authValidation'

import { useDispatch, useSelector } from 'react-redux'
import { login, logout, signup, fetchUser, verifyEmail, resetPassword } from '../../features/auth/authSlice'
// import { subscribeToPlan } from '../../features/payment/stripeSlice'
import { InfoContainer, SupportedBy, InfoTips } from '../Containers'
import './Auth.css'
import { UpdatePayment } from '../Payment'

export const AuthModal = ({ title = 'Open Modal', startingUseCase = 'sign-in'}) => {
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)	
	const needsNewPassword = useSelector((state) => state.auth.needsNewPassword)

	// Assign Open and Close Functions
	const [open, setOpen] = React.useState(false)
	const handleOpen = () => setOpen(true)
	const handleClose = () => setOpen(false) 

	// Set Form State
	const [useCase, setUseCase] = React.useState(startingUseCase)

	useEffect(() => {
		if (needsNewPassword) {
			setUseCase('reset-password')
		}
	}, [needsNewPassword])


	// Read User from Redux Store
	const [ isLoading, setIsLoading ] = React.useState(true)
	const username = useSelector((state) => state.auth.username)
	const userId = useSelector((state) => state.auth.userId)
	const user = {
		'username': username,
		'userId': userId,
	}

	// Fetch Current User, If One Exists
	const dispatch = useDispatch()
	useEffect(() => {	
		dispatch(fetchUser()).unwrap().then((user) => {	
			if (user.isAuthenticated) {
				handleClose()
			}
		}).catch((e) => {
			handleOpen()
		})
	},[username] )

	let content
	if (useCase == 'sign-up') {
		// Show "Sign-Up" Page
		content = <MultiStepForm 
			onSubmit={() => setUseCase('sign-in')}
			pages={[
				<SignUpForm 
					title='Welcome to The Engine. Please enter your details.'
					submitButton='Create Account'
				/>,
				<VerifyEmail
					title='Please Verify Your Email Address'
					submitButton='Verify Code'
				/>
			]}
		>
			<ChangeUseCase 
				useCases={['sign-in']}
				onChangeForm={(newUseCase) => setUseCase(newUseCase)} 
			/>
		</MultiStepForm>
	} else if ( useCase == 'reset-password' ) {
		content = <MultiStepForm 
			onSubmit={() => setUseCase('sign-in')} 
			pages={[
				<ChangePasswordForm
					title='Update Your Password'
				/>,
				<VerifyEmail
					title='Please Verify Your Email Address'
					submitButton='Verify Code'
				/>
			]}
		>
			<ChangeUseCase 
				useCases={['sign-in', 'sign-up']}
				onChangeForm={(newUseCase) => setUseCase(newUseCase)} 
			/>
		</MultiStepForm>
	} else {
		// Show "Sign-In" Page
		content = <SignInForm 	
			onSignedIn={() => setOpen(false)}
		>
			<ChangeUseCase 
				useCases={['reset-password', 'sign-up']}
				onChangeForm={(newUseCase) => setUseCase(newUseCase)} 
			/>
		</SignInForm>
	}

	return (
		<ThemeProvider theme={theme}>
		<div>
			<Modal
				open={open}
				onClose={() => setOpen(true)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box 
					className='modal-container'	
					sx={{
						'& .MuiTextField-root': { my: 1 },
					}}
					autoComplete="off"
				>
					<div className='auth-modal-content'>
						<Grid
							container
							spacing={2}
						>
							<Grid size={{md: 6, sm: 12}}>
								{ content }
								<Divider orientation="vertical" flexItem xs={2} sx={{ display: { xs: 'none', md:'block' } }}/>
							</Grid>
							<Grid size={{sm: 0, md: 6}} sx={{ display: { sm: 'none', md:'block' } }} >
								<InfoContainer width='100%'/>
							</Grid>
						</Grid>
					</div>
				</Box>
			</Modal>
		</div>
		</ThemeProvider>
	)
}

export const LogOutButton = ({ title = 'Log-Out'}) => {
	const dispatch = useDispatch()
	

	return (
		<div>
		<Button 
			className='logout-button' 
			variant='contained'
			onClick={
				() => {
					dispatch(logout())
				}
			}
		>
			{ title }
		</Button>
		</div>
	)
}

///////////////////////////////////////////////////////////////////
// Form for inputing User Info and Creating an Account 
///////////////////////////////////////////////////////////////////

const SignUpForm = ({ title, submitButton = 'Create Account', children, onFormData, onSubmit}) => {
	
	const dispatch = useDispatch()

	// Use React-Hook-Form to Validate and Collect Form Info
	const { 
		register, 
		handleSubmit, 
		watch, 
		getValues,
		setValue,
		setError,
		formState: { errors } 
	} = useForm({
		// Use 'Zod' Validation Library and Schema from features/auth/authValidation
		resolver: zodResolver(SignUpSchema),
	})

	if (process.env['IS_TESTING_AUTH'] === 'true') {
		setValue('name', process.env['TEST_NAME'])
		setValue('email', process.env['TEST_EMAIL'])
		setValue('password', process.env['TEST_PASSWORD'])
		setValue('confirmPassword', process.env['TEST_PASSWORD'])
	}


	const onChange = () => watch()

	// Validate and Submit form data to Cognito
	const createAccount = () => {
		const userInfo = getValues()
		return dispatch(signup(userInfo)).unwrap()
			.then((user) => {
				// console.log('signup user: ', user)
				return onSubmit()
			})
			.catch((e) => {
				if (e.source == 'cognito' && e.name == 'UsernameExistsException') {
					setError('email', { type: 'custom', message: 'Account with Email Address Already Exists'})
					return 
				} else {
					// console.log('root error: ', e)
					setError('root.serverError', {  type: 400, message: e.message})
				}
			})	
	}
	
	return (

		<form
			noValidate
			onSubmit={handleSubmit((d) => createAccount(d))}
		>
		<Typography 	
				sx={{
					alignSelf: 'start',
					marginTop: 0,
					marginBottom: 2,
				}}
			>	
				{ title }
		</Typography>

		<div id='user-info'>
			<TextField
				required
				fullWidth
				id="email"
				label="Email"
				name="email"
				autoComplete='email'
				{...register('email')}
				error={ Boolean(errors.email) }
				helperText={errors.email?.message}
			/>

			<TextField
				required
				fullWidth
				name="password"
				label="Password"
				type="password"
				id="password"
				error={ Boolean(errors.password) }
				{...register('password')}
				helperText={errors.password?.message}

				autoComplete="new-password"
			/>

			<TextField
				required
				fullWidth
				name="confirmPassword"
				label="Retype Password"
				type="password"
				id="confirmPassword"
				error={ Boolean(errors.confirmPassword) }
				{...register('confirmPassword')}
				helperText={errors.confirmPassword?.message}
				autoComplete='new-password'
			/>

			<TextField
				autoComplete="organizationId"
				name="organizationId"
				required
				fullWidth
				id="organizationId"
				label='Organization ID'
				{...register('organizationId')}
				autoFocus
			/>

			 { errors.root?.serverError?.type === 400 && <Alert severity="error"> { errors.root?.serverError?.message }</Alert> }
		</div>

		<Button
			type='submit'
			
			fullWidth
			variant="contained"
			sx={{ mt: 3, mb: 2 }}
		>
			{ submitButton }
		</Button>

		</form>
	
	)
}

///////////////////////////////////////////////////////////////////
// Form for Logging into an Account 
///////////////////////////////////////////////////////////////////
const SignInForm = ({ onChangeForm, onSubmitForm, onSignedIn, children }) => {

	// Read User from Redux Store
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
	const user = useSelector((state) => state.auth)
	const isEmailVerified = useSelector((state) => state.auth.isEmailVerified)

	const dispatch = useDispatch()
	const { 
		register, 
		handleSubmit, 
		setValue, 
		watch, 
		formState: { errors } 
	} = useForm()	  
	

	if (process.env['IS_TESTING_AUTH'] === 'true') {
		setValue('email', process.env['TEST_EMAIL'])
		setValue('password', process.env['TEST_PASSWORD'])
	}

	const onSubmit = async (data) => {
		await dispatch(login({ 'username': data.email, 'password': data.password})).unwrap()
			.then(async (user) => {
			if (
				user.isAuthenticated && 
				user.isEmailVerified === true && 
				user.username //&&
				// user.stripeId
			) {
				return onSignedIn()
			} 
		})
	}

	const onVerification = (isVerified) => {
		if (isVerified) {
			return onSignedIn()
		} 
	}

	const signin = (
		<Box
			sx={{
				marginTop: 4,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<Typography 
				component="h1" 
				variant="h5"
				sx={{
					alignSelf: 'start'
				}}
			>
				Sign In
			</Typography>

			<Box 
				component="form" 
				method='POST' 
				onSubmit={handleSubmit(onSubmit)}
				// onError={() => console.error('error')}
				width='100%'
				noValidate
				
			>
				<div className='auth-form'>	

					<TextField
						width='100%'
						required
						fullWidth
						id="email"
						type="email"
						label="Email Address"
						name="email"
						autoComplete="email"
						{...register("email", { required: true, pattern: '^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$'})}
						error={ Boolean(errors.email) }
						helperText={errors.email?.type === "required" ? 'Email is Required' : false }
					/>

					<TextField
						width='100%'
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="new-password"
						{...register('password', { required: true })}
						error={ Boolean(errors.password) }
						helperText={ errors.password ? 'Password is Required' : false }
					/>


					<FormControlLabel
						className='checkbox'
						control={<Checkbox value="allowExtraEmails" color="primary" />}
						label="Remember Me"
					/>

				</div>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					sx={{ mt: 3, mb: 2 }}
				>
					Sign In
				</Button>

				{ children }
			
			</Box>
		</Box>
	)

	let content = signin

	return (
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				{ isEmailVerified === false ? <VerifyEmail onVerification={onVerification} /> : signin  }
		</Container>
	)
}

///////////////////////////////////////////////////////////////////
// Forms for Changing User Password
///////////////////////////////////////////////////////////////////
const ChangePasswordForm = ({ title, submitButton = 'Change Password', children, onSubmit}) => {
	
	const dispatch = useDispatch()

	// Use React-Hook-Form to Validate and Collect Form Info
	const { 
		register, 
		handleSubmit, 
		watch, 
		getValues,
		setValue,
		setError,
		formState: { errors } 
	} = useForm({		
		// Use 'Zod' Validation Library and Schema from features/auth/authValidation
		resolver: zodResolver(ResetPasswordSchema),
	})

	if (process.env['IS_TESTING_AUTH'] === 'true') {
		setValue('oldPassword', process.env['TEST_PASSWORD'])
		setValue('newPassword', process.env['TEST_PASSWORD'])
		setValue('confirmPassword', process.env['TEST_PASSWORD'])
	}

	//const onChange = () => watch()

	// Validate and Submit form data to Cognito
	const resetPassword = async (data) => {
		// console.log('reset')

		const passwordInfo = getValues()
		return dispatch(ResetPassword(passwordInfo)).unwrap()
			.then((user) => {
				return onSubmit()
			})
			.catch((e) => {
				if (e.source == 'cognito' && e.name == 'UsernameExistsException') {
					setError('oldPassword', { type: 'custom', message: 'Account with Email Address Already Exists'})
					return 
				} else {
					// console.log('root error: ', e)
					setError('root.serverError', {  type: 400, message: e.message})
				}
			})		
	}
	
	return (

		<form
			noValidate
			onSubmit={handleSubmit((d) => resetPassword(d))}
		>
		<Typography 	
				sx={{
					alignSelf: 'start',
					marginTop: 0,
					marginBottom: 2,
				}}
			>	
				{ title }
		</Typography>

		<div id='user-info'>
			<TextField
				required
				fullWidth
				id="code"
				label="Confirmation Code"
				name="code"
				autoComplete='code'
				{...register('code')}
				error={ Boolean(errors.code) }
				helperText={errors.code?.message}
			/>

			<TextField
				required
				fullWidth
				name="newPassword"
				label="New Password"
				type="password"
				id="newPassword"
				error={ Boolean(errors.newPassword) }
				{...register('newPassword')}
				helperText={errors.newPassword?.message}

				autoComplete="new-password"
			/>

			<TextField
				required
				fullWidth
				name="confirmPassword"
				label="Retype Password"
				type="password"
				id="confirmPassword"
				error={ Boolean(errors.confirmPassword) }
				{...register('confirmPassword')}
				helperText={errors.confirmPassword?.message}
				autoComplete='new-password'
			/>

			 { errors.root?.serverError?.type === 400 && <Alert severity="error"> { errors.root?.serverError?.message }</Alert> }
		</div>

		<Button
			type='submit'
			fullWidth
			variant="contained"
			sx={{ mt: 3, mb: 2 }}
		>
			{ submitButton }
		</Button>

		</form>
	
	)
}


///////////////////////////////////////////////////////////////////
// Forms for Passing User Sign-Up Info and Verifying Account
///////////////////////////////////////////////////////////////////
const VerifyEmail = ({ title, isSignUp = true, submitButton = 'Verify Email', onSubmit }) => {

	const dispatch = useDispatch()
	const user = useSelector((state) => state.auth)
	const email = useSelector((state) => state.auth.username)

	// Use React-Hook-Form to Collect Verification Code
	const { 
		register, 
		handleSubmit, 
		watch, 
		getValues,
		setError,
		formState: { errors } 
	} = useForm()

	const verifyAccount = ({ code }) => {
		dispatch(verifyEmail({'email': email, 'code': code}))
			.unwrap()
			.then((user) => {
				// console.log(user)
				return onSubmit(user.isEmailVerified)
			})
			.catch((e) => {
				if (e.source == 'verification') {
					setError('code', { type: 'custom', message: e.message})
					return 
				} else {
					// console.log('root error: ', e)
					setError('root.serverError', {  type: 400, message: e.message})		
					return
				}
			})
	}
	
	return (
		<form
			noValidate
			onSubmit={handleSubmit((d) => verifyAccount(d))}
		>
		<Typography 	
				sx={{
					alignSelf: 'start',
					marginTop: 0,
					marginBottom: 2,
				}}
			>	
				{ title }
		</Typography>

		<div id='verify-account'>
			<TextField
				autoComplete="code"
				name="code"
				required
				fullWidth
				id="code"
				label="Verification Code"
				autoFocus
				{...register("code")}
				error={ Boolean(errors.code) }
				helperText={errors.code?.message}
			/>

			{ errors.root?.serverError?.type === 400 && <Alert severity="error"> { errors.root?.serverError?.message }</Alert> }
		</div>

		<Button
			type='submit'
			fullWidth
			variant="contained"
			sx={{ mt: 3, mb: 2 }}
		>
			{ submitButton }
		</Button>
		</form>
	)
}


///////////////////////////////////////////////////////////////////
// Page Markers for Multi-Step Forms
///////////////////////////////////////////////////////////////////

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#308fe8',
  },
}))

const PageMarker = ({ currentPage, pages, onSelectPage, onAfterFinalPage }) => {
	if (currentPage + 1 > pages.length) {
		// console.log(currentPage - 1, pages.length)
		onAfterFinalPage()
	}

	const PageMarkers = pages.map((currEl, index) => {
		const value = (index <= currentPage) * 100

		return (
			<Box width='100%' key={index}>
				<BorderLinearProgress 
					height='100%' 
					width='100%' 
					variant='determinate' 
					value={value} 
					onClick={(event) => onSelectPage(event, index)}
				/>
			</Box>
		)
		
	})

	
	return (
		<Stack direction='row' spacing={2} width='100%' height='20px'>

			{ PageMarkers }

		</Stack>
	)
}

///////////////////////////////////////////////////////////////////
// Container for multi-page forms. 
// All children must have onSubmit prop.
///////////////////////////////////////////////////////////////////

const MultiStepForm = ({ pages, onSubmit, children }) => {
	const [page, setPage] = React.useState(0)

	const advancePage = (event, newPage) => {
		if (newPage != undefined) {
			setPage(newPage)
		} else if (page + 1 == pages.length) {
			onSubmit()
		} else {
			setPage(page + 1)
		}
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<Box
				sx={{
					marginTop: 4,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					width: '100%',
				}}
			>
					<Typography 
						component="h1" 
						variant="h5"
						sx={{
							alignSelf: 'start',
							marginBottom: 0,
						}}
					>	
						Create your account
					</Typography>
					
						<div className='auth-form'>
							
							{	
								cloneElement(
									pages[page],
									{ ...pages[page].props, onSubmit: advancePage }
								)
							}

							<PageMarker 
								currentPage={page} 
								pages={pages.map(() => 1)} 
								onAfterFinalPage={() => onSubmit()}
							/>

							{ children }

						</div>
					
					
		{/*	</form>*/}
			</Box>
		</Container>
	)
}

///////////////////////////////////////////////////////////////////
// Select another form (ie sign-up, sign-in, or reset-password)
///////////////////////////////////////////////////////////////////
const ChangeUseCase = ({ 
	useCases=['sign-in'], 
	onChangeForm
}) => {

	return 	(
		<div className='auth-form'>
			{useCases.includes('sign-in') &&
				<Link 
					onClick={() => onChangeForm()}
					href="#" 
					variant="body2"
				>
					Already have an Account? Sign In
				</Link>
			}
			{useCases.includes('reset-password') &&
				<Link 
					onClick={() => onChangeForm('reset-password')}
					variant="body2"
				>
				  Forgot password?
				</Link>
			}
			{useCases.includes('sign-up') &&
				<Link 
					onClick={() => onChangeForm('sign-up')}
					href="#" 
					variant="body2" 
				>
					Don't have an account? Sign Up
				</Link>
			}
		</div>
	)
}
