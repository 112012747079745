import { fetchAuthSession } from 'aws-amplify/auth'
import axios from 'axios'
import fileDownload from 'js-file-download'

const apiInstance = axios.create()
apiInstance.interceptors.request.use(async (config) => {
	const session = await fetchAuthSession()
	//console.log(session.tokens.accessToken.toString())
	config.headers.authorization = 'Bearer ' + session.tokens.accessToken.toString()
	return config
})

const config = {
  apiEndpoint: process.env.API_ENDPOINT,
  clientId: process.env.USER_POOL_CLIENT_ID,
  poolId: process.env.USER_POOL_ID,
  MAX_CHUNK_SIZE_BYTES: Number(process.env.MAX_CHUNK_SIZE_BYTES),
  MIN_CHUNK_SIZE_BYTES: Number(process.env.MIN_CHUNK_SIZE_BYTES)
}

class ExploreTechAPI {
	constructor () {
		this.axios = apiInstance
		this.url = process.env.API_ENDPOINT
		this.clientId = process.env.USER_POOL_CLIENT_ID
		this.poolId = process.env.USER_POOL_ID
		this.MAX_CHUNK_SIZE_BYTES = Number(process.env.MAX_CHUNK_SIZE_BYTES)
		this.MIN_CHUNK_SIZE_BYTES = Number(process.env.MIN_CHUNK_SIZE_BYTES)
	}

	fetchResource(resource) {
			return new Promise(( resolve, reject ) => {
			this.axios.request(`${this.url}/${resource}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			}).then(( { data } ) => {
				resolve(data)

			}).catch((error) => {
				reject(error)
			})
		})
	}

	fetchResourceContents( { resource, id, type, path } ){
		const url = `${this.url}/${resource}/${id}/list${path}`

		return new Promise(( resolve, reject ) => {
			this.axios.request(url, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			}).then(( { data } ) => {
				resolve(data)
			}).catch((error) => {
				reject(error)
			})
		})
	}

	fetchToolContent({ id }){
		const url = `${this.url}/tools/${id}`

		return new Promise(( resolve, reject ) => {
			this.axios.request(url, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			}).then(( { data } ) => {
				resolve(data)
			}).catch((error) => {
				reject(error)
			})
		})
	}

	postTask({ taskId, filesystemId, args, cpu, memory }) {
		const url = `${this.url}/tools/${id}`

		const hardware = {
			'filesystems': filesystemId,
			'memory': memory,
			'cpu': cpu, 
			'gpu': false
		}

		return new Promise(( resolve, reject ) => {
			this.axios.request(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
                    hardware: hardware,
                    ...args.reduce((obj, item) => ({
                        ...obj,
                        [item.name]: item.value
                    }), {})
                })
			}).then(( { data } ) => {
				resolve(data)
			}).catch((error) => {
				reject(error)
			})
		})
	}

	selectResourceItem( { resource, name, id, type, path } ){

		const file = type == 'file' ? `/files${path.replace(/\/\s*$/, "")}` : ''

		return new Promise(async ( resolve, reject ) => {
		const url = `${this.url}/${resource}/${id}${file}`		
		const start = 0
		const chunkSize = 8 * 1024 * 1024

		if (resource == 'tools') {
			// Implement Launch Tool Task
			console.log('launch tool')
			resolve('launch tool')
		} else if (resource == 'filesystems') {		
			// Get File Size
			this.axios.request(url + '?init=true', {
				method: 'GET',
			// Request File
			}).then(({ data }) => {		
					this.axios.request(url, {
						method: 'GET',
						reponseType: 'blob',
						headers: {
							'Content-Range': `0-${data.size*chunkSize}`,
						},
					}).then(( { data } ) => {
										
						fileDownload(data, name)
						resolve('downloading')

					}).catch((error) => {
						reject(error)
					})
				}).catch((error) => {
					reject.error
				})
			}
		})
	}

}

export const EngineAPI = new ExploreTechAPI()

export default config
