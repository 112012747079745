import * as React from 'react'
import { 
	AppBar,
	Avatar, 
	Button, 
	Box, 
	Checkbox,
	Container,
	Collapse,
	CssBaseline,
	Divider,
	Drawer,
	FormControlLabel,
	Grid,
	IconButton,
	Icon,
	Link,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Modal, 
	Select,
	Stack,
	styled,
	SvgIcon,
	TextField,
	Toolbar,
	Typography,
	useMediaQuery,
} from '@mui/material'

import { 
	ExpandLess,
	ExpandMore,
} from '@mui/icons-material'
import ExploreTechSvg from '../../assets/icons/text_logo.svg?react'

import { 
	useNavigate, 
	useNavigation, 
	useMatches, 
	useMatch, 
	useLocation, 
	useResolvedPath,
	useParams,
} from "react-router-dom"

import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles'
import { theme } from '../../style/theme'

import { AuthModal, LogOutButton } from '../Auth/Auth'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { login, logout } from '../../features/auth/authSlice'


const Header = ({user, isActive}) => {
	return (
		<Stack 
			id="header"
			alignItems='start'	
		>
			<div>User ID: </div>
			<div>{user.username}</div>
			<FormControlLabel
				control={
					<Checkbox 
						checked={Boolean(isActive)} 
					/>
				}
				label='isActive'
			/>
		</Stack>
	)
}

export const Topbar = ({ sidebarContent, children }) => {
	// Find the "id" of the Current Page
	const matches = useMatches()
	const currentRoute = matches[matches.length - 1].id

	// Combine "Top", "Middle", and "Bottom" NavLinks into One List of All Nested Groups
	const allLinks = Object.values(sidebarContent)
		.reduce((accumulator, currentValue) => accumulator .concat(currentValue), [])
	
	// Make an Array containing Lists of Possible Nested Nav Groups
	const allNestedNavLinks  = allLinks
		.filter(navLink => 'children' in navLink)
		.map(linkHeader => Object.values(linkHeader.children)
			.map(child => child.link)
		)
		
	// Find the Set of Nested Nav Links Contain the Current Route
	const currentNestedNavLinks = allNestedNavLinks
		.find(children => children.includes(currentRoute))

	// Render Current Nested Nav Sibling's as Row of Links
	const links = currentNestedNavLinks?.map((page) => {
		const isUnderlined = page == currentRoute ? 'underline' : 'none'

		return (
			<ListItemButton 
				key={page}
				sx={{
					width: 'fit-content',
				}}
			>
				<Typography 
					sx={{ 
						textDecoration: isUnderlined, 
						textAlign: 'center',
						textTransform: 'capitalize',
					}}
				> 
					{page}
				</Typography>
			</ListItemButton>
		)
	})


	return (
		<Toolbar sx={{ backgroundColor: 'white', }}>	
			<Box sx={{ flexGrow: 1, display: { xs: 'flex'} }}>
				{ links }

				{ children }	
			</Box>
		</Toolbar>
	)
}

export const NavItem = ({ content }) => {

	const navigate = useNavigate()
	const redirect = () => {
		if ('link' in content) {
			if (
				content['link'].includes('https://') || 
				content['link'].includes('exploretech.ai')
			) {
				window.open(content['link'])
			} else {
				navigate(content.link)
			}
		}
		return
	}
	
	const item = (
		<ListItem 
			key={content.primary} 
			disablePadding
			sx={{
				width: '100%',
				maxWidth: '100%',
			}}
		>
			<ListItemButton 
				disableGutters={true}
				sx={{...content.sx, ...{ pl: '6px', pr: '6px' }}}  
			>
				<ListItemIcon
					onClick={redirect}
				>
					{ content.icon }		
				</ListItemIcon>
				<ListItemText 
					primary={content.primary} 
					secondary={content.secondary}
					primaryTypographyProps={{
						noWrap: true,
					}}
					secondaryTypographyProps={{
						noWrap: true,
					}}
					sx={{
						maxWidth: '100%',
						overflow: 'hidden',
						textWrap: 'nowrap',
						marginRight: '8px',
					}}
					onClick={redirect}
				/>
				{ 'iconRight' in content ? content.iconRight : undefined }	
			</ListItemButton>
		</ListItem>
	)

	return item
}

export const  NestedList = ({ header, content }) => {
	const isExpandedOnStart = 'isExpandedOnStart' in header ? header['isExpandedOnStart'] : false
	const [isExpanded, setIsExpanded] = React.useState(isExpandedOnStart)
	const [page, setPage] = React.useState('Home')

	let expandIcon = isExpanded ? 
		<ExpandLess onClick={() => setIsExpanded(false)} />
		:
		<ExpandMore onClick={() => setIsExpanded(true)}/>	

	const formattedContent = content.map((item, index) => {
		return <NavItem key={index} content={item} /> 
	})

	return (
	
	<List
		sx={{ 
			width: '100%', 
			maxWidth: 360, 
			bgcolor: 'background.main',
			padding: 0,
		}}
		component="nav"
		aria-labelledby="nested-list-subheader"
		
	>
		<NavItem 
			content={{
				...header,
				sx: {
					maxWidth: '100%',
					overflow: 'hidden',
					textWrap: 'nowrap',
				},
				iconRight: expandIcon,
			}}
		/>
		<Collapse in={isExpanded} timeout="auto" unmountOnExit>
			<List component="div" disablePadding>
				{
					formattedContent	
				}
			</List>
		</Collapse>
    </List>

	)
}

const CompanyLogo = ({ width}) => {
	
	return (
		<ThemeProvider theme={theme}>
		<Box 	
			sx={{
				bgcolor: 'primary.main',
				width: '100%',
				height: '100px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<SvgIcon 
				component={ExploreTechSvg} 
				inheritViewBox
				sx={{ 
					height:'auto',
					width: '100%',
					padding: '10px',
					color: 'white',
					'&:hover': {
						color: 'secondary.light',
					},
				}}
			/>	
		</Box>
		</ThemeProvider>
	)
}

export const Sidebar = ({ content, isOpen, onClose, width }) => {
	const [isClosing, setIsClosing] = React.useState(false)

	const handleDrawerClose = () => {
		setIsClosing(true)	
	}	

	const handleDrawerTransitionEnd = () => {
		setIsClosing(false)
		onClose()
	}

	const topContent = (
		<List 
			sx={{
				justifyContent: 'end',
				padding: 0,
			}}
			key="top"
		>
		{
			content['top'].map((item, index) => {
				if ('children' in item) {	
					return <NestedList key={item.primary} header={item} content={item.children} />
				} else {
					return <NavItem key={item.primary} content={item} />
				}
			})
		}
		</List>
	)

	
	const bottomContent = (
		<List 
			sx={{
				justifyContent: 'end',
				alignSelf: 'bottom',
				padding: 0,
			}}
			key="bottom"
		>
		{ 	
			
			content['bottom'].map((item, index) => {
				if ('children' in item) {
					return <NestedList key={item.primary} header={item} content={item.children} />
				} else {
					return <NavItem key={item.primary} content={item} />
				}
			})


		}
		</List>
	)

	return (	
		<ThemeProvider theme={theme}>
		<Box
			component="nav"
			sx={{ 'width': { 'sm': width }, 'flexShrink': { 'sm': 0 } }}
		>
			<Drawer
				color='background'
				variant="temporary"
				open={isOpen}
				onTransitionEnd={handleDrawerTransitionEnd}
				onClose={handleDrawerClose}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				PaperProps={{
					sx: {
						borderRight: 'none',
					}
				}}
				sx={{
					display: { xs: 'block', sm: 'none' },
					'& .MuiDrawer-paper': { 
						boxSizing: 'border-box', 
						width: width,
						borderRight: 'none !important',
					},
				}}

			>
				
				<div 
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%',
					}}
				>	
				{ topContent }
				
				{ bottomContent }
				</div>
			</Drawer>
			<Drawer
				color='background'
				variant="permanent"
				sx={{
					display: { xs: 'none', sm: 'block' },
					'& .MuiDrawer-paper': { 
						boxSizing: 'border-box', 
						minWidth: width,
						maxWidth: width,
					},
				}}
				open
			>

				<CompanyLogo />	

				<div 
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%',
						width: 'inherit',
					}}
				>	

				{ topContent }
				
				{ bottomContent }
				
				</div>
				
			</Drawer>
		</Box>
		</ThemeProvider>
	)	
}
