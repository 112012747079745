import * as z from 'zod'

// Create a Validation Schema for the Sign-Up Form
export const SignUpSchema = z.object({
		name: z.string(),
		organizationId: z.string(),
		email: z.string().email(),
		password: z.string()
			.min(10, { message: "Must be 10 or more characters long" })
			.regex(/[A-Z]/, { message: 'Add Upper Case Letter'})
			.regex(/[a-z]/, { message: 'Add Lower Case Letter'})
			.regex(/[`!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~ ]/, { message: 'Add Special Character'}),
		confirmPassword: z.string()
			.min(10, { message: "Must be 10 or more characters long"})
			.regex(/[A-Z]/, { message: 'Add Upper Case Letter'})
			.regex(/[a-z]/, { message: 'Add Lower Case Letter'})
			.regex(/[`!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~ ]/, { message: 'Add Special Character'}),
	})
	.partial({
		name: true,
	})
	.superRefine((arg, ctx) => {
		// Check if Password and Confirmed Password Match
		// If they don't, then throw an error
		if (arg.password != arg.confirmPassword) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom, // customize your issue
				message: "Passwords Do Not Match",
				path: ['confirmPassword']
			})
		}

		return z.NEVER
	})

// Create a Validation Schema for the Sign-Up Form
export const ResetPasswordSchema = z.object({
		code: z.string(),
		newPassword: z.string()
			.min(10, { message: "Must be 10 or more characters long" })
			.regex(/[A-Z]/, { message: 'Add Upper Case Letter'})
			.regex(/[a-z]/, { message: 'Add Lower Case Letter'})
			.regex(/[`!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~ ]/, { message: 'Add Special Character'}),
		confirmPassword: z.string()
			.min(10, { message: "Must be 10 or more characters long"})
			.regex(/[A-Z]/, { message: 'Add Upper Case Letter'})
			.regex(/[a-z]/, { message: 'Add Lower Case Letter'})
			.regex(/[`!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~ ]/, { message: 'Add Special Character'}),
	})
	.partial({
		name: true,
	})
	.superRefine((arg, ctx) => {
		// Check if Password and Confirmed Password Match
		// If they don't, then throw an error
		if (arg.newPassword != arg.confirmPassword) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom, // customize your issue
				message: "Passwords Do Not Match",
				path: ['confirmPassword']
			})
		}

		return z.NEVER
	})


