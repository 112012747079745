import { blue, red } from '@mui/material/colors'
import { createTheme, ThemeProvider } from '@mui/material/styles'


const exploreTechBlue = {
	50: '#e0e4e8',
	100: '#b5bfc8',
	200: '#8394a3',
	300: '#51697e',
	400: '#2c4963',
	500: '#072947',
	600: '#062440',
	700: '#051f37',
	800: '#04192f',
	900: '#020f20',
	'A100': '#aec8f7',
	'A300': '#85acf4',
	'A500': '#5d90ff',
	'A400': '#466cb4',
	'A700': '#3a5a96',
}

const colors = {
	blue: {
		main: blue[500],
	},
	background: {
		main: exploreTechBlue[50],
		dark: exploreTechBlue[100]
	},
	primary: {
		light: exploreTechBlue[300],
		main: exploreTechBlue[500],
		dark: exploreTechBlue[700],
		darker: exploreTechBlue[900],
	},
	secondary: {
		light: exploreTechBlue['A100'],
		main: exploreTechBlue['A500'],
		dark: exploreTechBlue['A700']
	}
}

export const theme = createTheme({
	typography: {
		poster: {
			fontSize: 64,
			color: 'red',
		},
		h6: {
			fontSize: '20px',
			lineHeight: '32px',
			fontWeight: 500,
		},
		subtitle1: {
			fontSize: '16px',
			lineHeight: '28px',
			fontWeight: 500,
		},
		subtitle2: {
			fontSize: '14px',
			lineHeight: '28px',
			fontWeight: 500,
		},
		// Disable h3 variant
		h3: undefined,

	},
	palette: colors,
	components: {
		typography: {
			h6: {
				fontSize: '20px',
				lineHeight: '32px',
				fontWeight: 500,
			},
			subtitle1: {
				fontSize: '16px',
				lineHeight: '28px',
				fontWeight: 500,
			},
			poster: {
				fontSize: 64,
				color: 'red',
			},
		},
		MuiTypography: {
			defaultProps: {

			},
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					backgroundColor: exploreTechBlue[50],
					border: 'none',
				}
			}
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: '36px'
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				//Name of the slot
				root: {	
					":hover": {
						color: colors.primary.main,
						backgroundColor: colors.secondary.main,
					}
				}
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					boxShadow: 'none',
				}
			},
		},
		MuiListItem: {
			styleOverrides: {
				root: {	
					variants: [
						{
							// `dashed` is an example value, it can be any name.
							props: { variant: 'apikey' },
						  	style: {
								borderRadius: "10px",
								"&:hover": {
									backgroundColor: colors.secondary.light
								}
						  	},
						},
					],
				}
			}
		}
	},
})



