import React from "react"
import {
	Box, 
	Grid2 as Grid,
	Typography,
} from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '../style/theme';
import Page from "./Page"


const Support = () => {

    return (
        <Page name={'Support'}>
            <ThemeProvider theme={theme}>
                <Grid container direction="column" sx={{height: "100%"}}>
                    <Grid size="auto">
                        <Typography variant="h6">
                            Send us a message at info@exploretech.ai.
                        </Typography>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </Page>
    )
}


export default Support