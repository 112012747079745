import React, {useState, useEffect} from "react"
import { useSelector } from "react-redux";
import { 
    IconButton,
	Box, 
	Grid2 as Grid,
	Typography,
	List,
    ListItem,
    ListItemText,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Collapse,
    Paper,
    TablePagination
} from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '../style/theme';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Page from "./Page"
import { EngineApi } from "../main";
import { Batch, BatchStatus } from "../engine/src/batches";


const BatchItem = ({batch}) => {

    const [open, setOpen] = useState(false)
    const [status, setStatus] = useState(null)
    const [percentComplete, setPercentComplete] = useState(0)

    const updateStatus = async () => {
        EngineApi.get(
            `/batches/${batch.batchId}`
        )
        .then((response) => {
            const batchStatus = BatchStatus.fromJson(response.data)
            const complete = batchStatus.completed() / batch.nJobs * 100
            setStatus(batchStatus)
            setPercentComplete(complete)
        })
        .catch((error) => {
            console.error(error)
        })
    }

    useEffect(() => {
        updateStatus()
    }, [batch])

    
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton 
                        aria-label="expand-row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{batch.batchTool.toolName}</TableCell>
                <TableCell>{batch.nJobs}</TableCell>
                <TableCell>{`${percentComplete}%`}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{mb:2}}>
                            <Typography variant="h6">
                                Batch Details
                            </Typography>
                            <Typography variant="secondary">
                                {batch.batchId}
                            </Typography>
                            
                        </Box>
                        <Grid container direction="row" spacing={4}>
                            <Grid size="grow">
                                <Typography variant="h6">
                                    Hardware
                                </Typography>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>CPU</TableCell>
                                            <TableCell>{batch.batchHardware.cpu}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Memory</TableCell>
                                            <TableCell>{batch.batchHardware.memory}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Filesystems</TableCell>
                                            <TableCell>
                                                <List dense sx={{m:0, p:0}}>
                                                    {batch.batchHardware.filesystemList.map((fs) => {
                                                        return (
                                                            <ListItem key={fs.filesystemId} sx={{m:0, p:0}}>
                                                                <ListItemText primary={fs.filesystemName}/>
                                                            </ListItem>
                                                        )
                                                    })}
                                                </List>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid size="grow">
                                <Typography variant="h6">
                                    Summary
                                </Typography>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Submitted</TableCell>
                                            <TableCell>{status && status.submitted}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Pending</TableCell>
                                            <TableCell>{status && status.pending}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Runnable</TableCell>
                                            <TableCell>{status && status.runnable}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Starting</TableCell>
                                            <TableCell>{status && status.starting}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Running</TableCell>
                                            <TableCell>{status && status.running}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Succeeded</TableCell>
                                            <TableCell>{status && status.succeeded}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Failed</TableCell>
                                            <TableCell>{status && status.failed}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}


const Batches = () => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [batchList, setBatchList] = useState([])
    const idToken = useSelector((state) => state.auth.idToken)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const fetchData = async () => {
        await EngineApi.get(
            "/batches"
        )
        .then((response) => {
            const newBatches = []
            for (const item of response.data) {
                newBatches.push(Batch.fromJson(item))
            }
            setBatchList(newBatches)
        })
        .catch((error) => {
            console.error(error)
        })
    }

    useEffect(() => {
        if (idToken){
            fetchData()
        }
    }, [idToken])

    return (
        <Page name="Batches" style={{height: "80%"}}>
            <ThemeProvider theme={theme}>
                <Grid container direction="column" xs={12} sx={{height: "90%"}}>
                    <Paper sx={{height: "100%"}}>
                        <TableContainer sx={{height: "100%"}}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell/>
                                        <TableCell>Tool Name</TableCell>
                                        <TableCell>Jobs</TableCell>
                                        <TableCell>Completed</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {batchList
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((item, index) => {
                                            return <BatchItem key={index} batch={item}/>
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={batchList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            </ThemeProvider>
        </Page>
    )
}

export default Batches
