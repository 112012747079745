import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { thunk } from 'redux-thunk'

import PaymentAPI from '../payment'

////////////////////////////////////////////////////////
// Create Stripe User
//////////////////////////////////////////////////////////

export const createPaymentCustomer = createAsyncThunk(
	'payment/signup',
	async (email, thunkAPI) => {
		console.log('createStripeUser thunk called')
		try {
			const customer = await PaymentAPI.createCustomer(email)
			console.log('thunk customer: ', customer)
			return { 
				stripeId: customer.stripeId,
				subscription: customer.subscriptionId,
				clientSecret: customer.clientSecret,
			}
		} catch (error) {
			if ( error.message == 'NetworkError when attempting to fetch resource.' ){
				return thunkAPI.rejectWithValue({
					'source': 'stripe',
					'name': 'NetworkError',
					'message': 'NetworkError when attempting to reach Stripe API',
					'value': error
				})
			} else {
				return thunkAPI.rejectWithValue({
					'source': 'stripe',
					'name': 'NetworkError',
					'message': 'NetworkError when attempting to reach Stripe API',
					'value': error
				})

			}
		}
	}
)

export const subscribeToPlan = createAsyncThunk(
	'payment/subscribe',
	async (stripeId, thunkAPI) => {
		try {
			if (stripeId) {
				const subscription = await PaymentAPI.createSubscription(stripeId)

				return { 
					'subscription': subscription['subscriptionId'],
					'clientSecret': subscription['clientSecret'],
				}
			} else {
				return thunkAPI.rejectWithValue({
					'source': 'stripe',
					'name': 'SubscriptionError',
					'message': 'Cannot Create Stripe Subscription',
					'value': subscription
				})
			}
		} catch (error) {
			return thunkAPI.rejectWithValue({
				'source': 'stripe',
				'name': 'NetworkError',
				'message': 'NetworkError when attempting to reach Stripe API',
				'value': error
			})
		}
	}
)

export const paymentSlice = createSlice({
	name: 'payment',
	initialState: {
		stripeId: undefined,
		subscription: undefined,
		clientSecret: undefined,
	},
	extraReducers: (builder) => {
		builder
			// Create Stripe User
			.addCase(createPaymentCustomer.fulfilled, ( state, action ) => {
				state = { ...state, ...action.payload }
				console.log('fulfilled. state=', state)
				return state
			})
			.addCase(createPaymentCustomer.rejected, ( state, action ) => {
				state = {
					stripeId: undefined
				}

				console.log('failed to create stripe user')
				return state
			})
			.addCase(subscribeToPlan.fulfilled, ( state, action ) => {
				state = { ...state, ...action.payload }
				return state
			})
			.addCase(subscribeToPlan.rejected, ( state, action ) => {
				state = { subscription: undefined }

				console.log('failed to create subscription')
				return state
			})
	},
})

export default paymentSlice.reducer


