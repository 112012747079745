export default function setupAxios(axios, store) {
    axios.interceptors.request.use(
        (config) => {

            const {
                auth: { idToken },
            } = store.getState()

            if (idToken) {
                config.headers.Authorization = `Bearer ${idToken}`
            }

            return config;
        },
       (err) => Promise.reject(err)
    )
}
