import { Tool, Hardware } from './tools'


export class Batch {
    constructor(batchId, batchTool, nJobs, batchHardware) {
        this.batchId = batchId
        this.batchTool = batchTool
        this.nJobs = nJobs
        this.batchHardware = batchHardware
    }


    isEqual(obj) {
        try {
            let isEqual = true
            isEqual = isEqual && (this.batchId === obj.batchId)
            isEqual = isEqual && (this.nJobs === obj.nJobs)

            isEqual = isEqual && this.batchTool.isEqual(obj.batchTool)
            isEqual = isEqual && this.batchHardware.isEqual(obj.batchHardware)

            return isEqual
        } catch {
            return false
        }
    }


    toJson() {
        const batchJson = {
            batch_id: this.batchId,
            batch_tool: this.batchTool.toJson(),
            n_jobs: this.nJobs,
            batch_hardware: this.batchHardware.toJson()
        }
        return batchJson
    }


    static fromJson(batchJson) {
        const batch = new Batch(
            batchJson.batch_id,
            Tool.fromJson(batchJson.batch_tool),
            batchJson.n_jobs,
            Hardware.fromJson(batchJson.batch_hardware)
        )
        return batch
    }
}


export class BatchStatus {
    constructor(submitted, pending, runnable, starting, running, succeeded, failed) {
        this.submitted = submitted
        this.pending = pending
        this.runnable = runnable
        this.starting = starting
        this.running = running
        this.succeeded = succeeded
        this.failed = failed
    }


    completed() {
        return this.succeeded + this.failed
    }


    isEqual(obj) {
        try {
            let isEqual = true
            isEqual = isEqual && (this.submitted === obj.submitted)
            isEqual = isEqual && (this.pending === obj.pending)
            isEqual = isEqual && (this.runnable === obj.runnable)
            isEqual = isEqual && (this.starting === obj.starting)
            isEqual = isEqual && (this.running === obj.running)
            isEqual = isEqual && (this.succeeded === obj.succeeded)
            isEqual = isEqual && (this.failed === obj.failed)
            return isEqual
        } catch {
            return false
        }
    }


    toJson() {
        const batchStatusJson = {
            submitted: this.submitted,
            pending: this.pending,
            runnable: this.runnable, 
            starting: this.starting,
            running: this.running,
            succeeded: this.succeeded,
            failed: this.failed
        }
        return batchStatusJson
    }


    static fromJson(batchStatusJson) {
        const batchStatus = new BatchStatus(
            batchStatusJson.submitted,
            batchStatusJson.pending,
            batchStatusJson.runnable,
            batchStatusJson.starting,
            batchStatusJson.running,
            batchStatusJson.succeeded,
            batchStatusJson.failed
        )
        return batchStatus
    }
}